import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {delay, map, withLatestFrom} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AppService} from './shared/services/app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    pageTitle = document.title;
    attentionMessage = '👉 Don\'t miss this...!';
    public deviceInfo = null;
    public isMobilevar: any = 'false';
    public isTabletvar: any = 'false';
    public isDesktopvar: any = 'false';
    // For Progressbar
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map(v => v[1]),
    );

    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange() {
        if (document.hidden) {
            document.title = this.attentionMessage;
        } else {
            document.title = this.pageTitle;
        }
    }

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private loader: LoadingBarService, translate: TranslateService, private deviceService: DeviceDetectorService, private appService: AppService) {
        if (isPlatformBrowser(this.platformId)) {
            const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
            console.log(selectedLanguage);
            if (selectedLanguage !== undefined && selectedLanguage !== null) {
                translate.setDefaultLang(selectedLanguage[0].shortCode);
            } else {
                this.appService.setLocalStorageItem('lang', [{
                    icon: 'assets/images-custom/language/english.png',
                    lang: 'English',
                    dispText: 'English',
                    selected: true,
                    applyText: 'Apply',
                    shortCode: 'en',
                    applyHeading: 'Choose Language',
                }], true);
                translate.setDefaultLang('en');
                window.location.reload();
            }
            translate.addLangs(['en', 'ta']);
        }
        this.detectDevice();
        this.isMobile();
        this.isTablet();
        this.isDesktop();
    }

    public detectDevice() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
    }

    public isMobile() {
        this.isMobilevar = this.deviceService.isMobile();
        this.appService.setLocalStorageItem('isMobile', this.isMobilevar, true);
    }

    public isTablet() {
        this.isTabletvar = this.deviceService.isTablet();
        this.appService.setLocalStorageItem('isTablet', this.isTabletvar, true);
    }

    public isDesktop() {
        this.isDesktopvar = this.deviceService.isDesktop();
        this.appService.setLocalStorageItem('isDesktop', this.isDesktopvar, true);
    }
}
