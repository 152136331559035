import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavService} from '../../shared/services/nav.service';
import {ProductService} from '../../shared/services/product.service';
import {AppService} from '../../shared/services/app.service';
import {HomeService} from '../../shared/services/home.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-cart-popover',
    templateUrl: './cart-popover.component.html',
    styleUrls: ['./cart-popover.component.scss']
})
export class CartPopoverComponent implements OnInit {

    public layoutsidebar: boolean = false;
    public activeItem: string;
    public products: any = [];
    public imageURL = '';
    public showCartPopup = false;
    isLoggedIn: boolean = false;
    grandTotal = 0.0;

    constructor(public productService: ProductService, private appService: AppService,
                public homeService: HomeService, public navService: NavService,
                public changeDetect: ChangeDetectorRef, public router: Router) {
        // this.productService.cartItems.subscribe(response => {this.products = response;console.log(response)});
    }

    ngOnInit(): void {
        const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
        const categoryData = {
            lang: selectedLanguage[0].shortCode
        };
        this.navService.settings.subscribe((settings) => {
            this.imageURL = settings.asseturl;
        });

        /*this.homeService.getCartProducts(categoryData).subscribe((products) => {
            this.products = products;
        });*/
        this.navService.cartItems.subscribe(response => {
            this.products = response;
            this.calculateCartTotal();
        });
        this.navService.localcartItems.subscribe(response => {
            if (response.length > 0) {
                response.forEach(item => {
                    item['color'] = {
                        name: item.av[0].value
                    };
                    item['size'] = {
                        name: item.av[1].value
                    };
                });
                this.navService.cartItems.next(response);
            }
        });
        this.navService.cartPopupOver.subscribe(response => {
            this.showCartPopup = response;
        });
        this.navService.loggedIn.subscribe(response => {
            this.isLoggedIn = response;
        });
    }

    public calculateCartTotal() {
        let cartTotal = 0.0;
        this.products.forEach(product => {
            let price = product.sp;
            if (product.bdi) {
                price = product.bdp;
            }
            cartTotal += (price * product.qty);
        });
        this.grandTotal = cartTotal;
    }


    // Increament
    increment(product, qty = 1) {
        this.productService.updateCartQuantity(product, qty);
    }

    // Decrement
    decrement(product, qty = -1) {
        this.productService.updateCartQuantity(product, qty);
    }

    public removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    public addToWishlist(product: any) {
        this.productService.addItemToWishlist(product.pv);
        this.productService.removeCartItem(product);
    }

    // Is active
    isActive(menuItem) {
        return this.activeItem === menuItem;
    }

    // Sidebar Toggle
    layoutSidebarToggle() {
        this.navService.cartPopupOver.next(!this.navService.cartPopupOver.getValue());
    }

    // collapse
    opensettingcontent(val) {
        if (this.activeItem === val) {
            this.activeItem = '';
        } else {
            this.activeItem = val;
        }
    }

    // Layout Type
    customizeLayoutType(val) {
        if (val === 'rtl') {
            document.body.classList.remove('ltr');
            document.body.classList.add('rtl');
        } else {
            document.body.classList.remove('rtl');
            document.body.classList.add('ltr');
        }
    }

    // Set Theme color
    customizeThemeColor(event) {
        document.documentElement.style.setProperty('--theme-deafult', event.target.value);
    }

    customizeLayoutDark() {
        document.body.classList.toggle('dark');
    }

    showcheckout() {
        this.navService.buyNow.next(null);
        if (this.isLoggedIn) {
            this.navService.cartPopupOver.next(false);
            this.router.navigate(['/shop/checkout']);
        } else {
            this.appService.setLocalStorageItem('opt', {
                fromPage: null,
                operation: 'navigate',
                data: null,
                action: null,
                toPage: '/shop/checkout',
                miscParams: null
            }, true);
            this.navService.cartPopupOver.next(false);
            this.router.navigate(['/user/login']);
        }
    }

    getRandNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    navigateToProduct(name, vi) {
        // this.navService.cartPopupOver.next(false);
        // this.router.navigate(['/shop/product4/' + name + '/' + vi + '-' + this.getRandNumber(1000, 10000) + '/view']);
    }
}
