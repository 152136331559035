import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {QuickViewComponent} from '../../modal/quick-view/quick-view.component';
import {CartModalComponent} from '../../modal/cart-modal/cart-modal.component';
import {Product, Variants} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {HomeService} from '../../../services/home.service';
import {each, find, includes} from 'lodash';
import {ProductCartWishlist} from '../../../classes/cartwishlist';
import {NavService} from '../../../services/nav.service';
import {AppService} from '../../../services/app.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-ullu-product-box',
    templateUrl: './ullu-product-box.component.html',
    styleUrls: ['./ullu-product-box.component.scss']
})
export class UlluProductBoxComponent implements OnInit, OnChanges {

    @Input() product: Product;
    @Input() currency: any = this.productService.Currency; // Default Currency
    @Input() thumbnail = false; // Default False
    @Input() onHowerChangeImage = false; // Default False
    @Input() cartModal = false; // Default False
    @Input() loader = false;
    @Input() imageUrl = '';
    @Input() layoutView = '';
    @ViewChild('quickView') QuickView: QuickViewComponent;
    @ViewChild('cartModal') CartModal: CartModalComponent;
    @Input() userWishlistIds = [];
    @Input() cartItems = [];
    images = [];
    randNumber = 1239;
    productVariants = {};
    public selectedAttribs = [];
    selectedVariant: Variants;
    addedToWishlist = false;
    cartCount = 0;
    public ImageSrc: string;
    selectedAttribDetails = [];
    sizeQuantities = {};

    constructor(private productService: ProductService, public homeService: HomeService,
                public navService: NavService, private appService: AppService,
                private router: Router, public cdr: ChangeDetectorRef, public toastrService: ToastrService) {
    }

    updateLayout(layout) {
        this.layoutView = layout;
    }

    ngOnInit(): void {
        if (this.loader) {
            setTimeout(() => {
                this.loader = false;
            }, 2000); // Skeleton Loader
        }
        this.selectedAttribs.push(this.product.cmb[0].id);
        this.selectedAttribs.push(this.product.smb[0].id);
        this.product.cmb[0].selected = true;
        this.product.smb[0].selected = true;
        this.selectedAttribDetails.push({
            name: 'color',
            value: this.product.cmb[0].name
        });
        this.selectedAttribDetails.push({
            name: 'size',
            value: this.product.smb[0].name
        });
        this.product.variants.forEach(variant => {
            this.productVariants[variant.code] = variant;
        });
        if (this.selectedVariant === undefined) {
            this.selectedVariant = this.productVariants[this.selectedAttribs.join('-')];
        }
        this.randNumber = this.getRandNumber(1000, 10000);
        this.images = this.getImage(this.selectedVariant.oi);
        this.refreshCartWishlist();
        this.setSizeQuantities();
    }

    // Change Variants Image
    ChangeVariantsImage(src) {
        this.ImageSrc = src;
    }

    getImagePath(image) {
        return this.imageUrl + image;
    }

    getImage(image) {
        if (image !== undefined && image !== '') {
            const img = image.split(',');
            if (img.length > 0) {
                const imgs = [];
                each(img, (i) => {
                    imgs.push(this.imageUrl + i);
                });
                return imgs;
            } else {
                return [];
            }
        } else {
            return [];
        }

    }

    addToCart() {
        if (this.selectedVariant.qty > 0) {
            this.productService.addVariantToCart(new ProductCartWishlist(this.product.id, this.product.name, null, this.selectedVariant.dp, 1, this.selectedVariant.sp, this.selectedVariant.dp, this.selectedVariant.mp, this.selectedVariant.id, this.selectedVariant.id, this.selectedVariant.mi, this.selectedVariant.si, this.selectedVariant.oi, 1, null, null, null, this.selectedAttribDetails));
        } else {
            this.toastrService.error('Selected variant not available!');
        }
    }

    addToWishlist() {
        this.productService.addItemToWishlist(this.selectedVariant.id);
    }

    removeWishlistItem() {
        this.productService.removeWishlistItem(this.selectedVariant.id);
    }

    changeVariantColor(orderIndex: number, id: number) {
        this.selectedAttribs[0] = id;
        let selectedAttribDet;
        (this.product.cmb).forEach(item => {
            if (item.id === id) {
                selectedAttribDet = item;
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        this.ImageSrc = null;
        this.selectedVariant = this.productVariants[this.selectedAttribs.join('-')];
        console.log(this.selectedVariant);
        this.selectedAttribDetails[0].value = selectedAttribDet.name;
        this.refreshCartWishlist();
        this.images = this.getImage(this.selectedVariant.oi);
        this.setSizeQuantities();
    }

    changeVariantSize(orderIndex: number, id: number) {
        this.selectedAttribs[1] = id;
        let selectedAttribDet;
        (this.product.smb).forEach(item => {
            if (item.id === id) {
                selectedAttribDet = item;
                item.selected = true;
            } else {
                item.selected = false;
            }
        });
        this.selectedVariant = this.productVariants[this.selectedAttribs.join('-')];
        this.selectedAttribDetails[1].value = selectedAttribDet.name;
        this.refreshCartWishlist();
        this.images = this.getImage(this.selectedVariant.oi);
    }

    containsInWishlist(variantId: number) {
        return includes(this.userWishlistIds, variantId);
    }

    getCartCount() {
        const thisVal = this;
        const cartItem = find(this.cartItems, item => {
            return item.pv === thisVal.selectedVariant.id;
        });
        if (cartItem !== undefined) {
            this.cartCount = cartItem.qty;
        } else {
            this.cartCount = 0;
        }
    }

    getRandNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    refreshCartWishlist() {
        if (this.selectedVariant !== undefined) {
            this.addedToWishlist = this.containsInWishlist(this.selectedVariant.id);
            this.getCartCount();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cartItems !== undefined) {
            this.cartItems = changes.cartItems.currentValue;
            this.refreshCartWishlist();
        }
        if (changes.userWishlistIds !== undefined) {
            this.userWishlistIds = changes.userWishlistIds.currentValue;
            this.refreshCartWishlist();
        }
    }

    buyNow() {
        if (this.selectedVariant.qty > 0) {
            this.navService.buyNow.next(new ProductCartWishlist(this.product.id, this.product.name, null, this.selectedVariant.dp, 1, this.selectedVariant.sp, this.selectedVariant.dp, this.selectedVariant.mp, this.selectedVariant.id, this.selectedVariant.id, this.selectedVariant.mi, this.selectedVariant.si, this.selectedVariant.oi, 1, null, null, null, this.selectedAttribDetails));
            if (this.navService.loggedIn.getValue()) {
                this.router.navigate(['/shop/checkout']);
            } else {
                this.router.navigate(['/user/login']);
            }
        } else {
            this.toastrService.error('Selected variant not available!');
        }
    }

    setSizeQuantities() {
        const variantQty = {};
        this.product.variants.forEach(variant => {
            variantQty[variant.code] = variant.qty;
        });
        const sizeQty = {};
        this.product.smb.forEach(size => {
            size.qty = variantQty[this.selectedAttribs[0] + '-' + size.id];
        });
    }
}
