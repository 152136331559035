import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TokenStorageService} from '../services/token-storage.service';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(private token: TokenStorageService, private router: Router, private authService: AuthService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const loginPath = '/user/login';
        const token = this.token.getToken();
        if (token != null) {
            authReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + token)});
        }
        else{
            // this.router.navigate(['/user/login']);
        }
        return next.handle(authReq).pipe(tap(() => {
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401 || window.location.pathname === loginPath) {
                        console.log('sdfsdf s dfsd');
                        return;
                    }
                    if (this.refreshTokenInProgress) {
                        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                        // – which means the new token is ready and we can retry the request again
                        return this.refreshTokenSubject.pipe(
                            filter(result => result !== null),
                            take(1),
                            switchMap(() => next.handle(this.addAuthenticationToken(req))));
                    } else {
                        this.refreshTokenInProgress = true;

                        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                        this.refreshTokenSubject.next(null);

                        // Call auth.refreshAccessToken(this is an Observable that will be returned)
                        return this.authService
                            .refresh(token)
                            .subscribe((tokenresp: any) => {
                                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                // for the next time the token needs to be refreshed
                                this.refreshTokenInProgress = false;
                                this.refreshTokenSubject.next(tokenresp);

                                return next.handle(this.addAuthenticationToken(req));
                            }, (refreshError: any) => {
                                console.log('error! ');
                                this.refreshTokenInProgress = false;
                                this.token.signOut();
                                return next.handle(refreshError);
                                // return Observable.throw(refreshError);
                            });
                    }
                }
            }
        ));
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = this.token.getToken();

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + accessToken
            }
        });
    }
}
