import {Component, OnInit} from '@angular/core';
import {NavService} from '../../services/nav.service';

@Component({
    selector: 'app-menu-two',
    templateUrl: './menu-two.component.html',
    styleUrls: ['./menu-two.component.scss']
})
export class MenuTwoComponent implements OnInit {
    showWomen = false;
    selectedMenu = 'home';
    menus: any;

    constructor(private navService: NavService) {
        this.navService.selectedMenu.subscribe(response => {
            this.selectedMenu = response;
        });
        this.navService.settings.subscribe(response => {
            this.menus = response.menu;
            console.log(this.menus);
        }, error => {
            this.menus = [{
                children: null,
                code: 'home',
                displayOrder: 1,
                filter: 'p:0',
                id: 3,
                image: 'home',
                imageType: 'TEXT',
                name: 'முகப்பு',
                navPath: '/',
                parent: null
            }];
        });
    }

    ngOnInit(): void {
    }
}
