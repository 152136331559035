<div>
    <!-- theme setting -->
    <!--<a href="javascript:void(0)">
        <div class="setting-sidebar" id="setting-icon" (click)="layoutSidebarToggle()">
            <div>
                <i class="fa fa-cog" aria-hidden="true"></i>
            </div>
        </div>
    </a>-->
    <div id="setting_box" class="cart-box" [class.opencart]="showCartPopup">
        <a href="javascript:void(0)" class="sidebar-overlay" (click)="layoutSidebarToggle()"></a>
        <div class="setting_box_body">
            <div>
                <div class="sidebar-back d-flex justify-content-between" (click)="layoutSidebarToggle()">
                    <!--<i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Cart-->
                    <span>My Cart</span>
                    <span><i class="fa fa-times-circle" style="font-size: 20px"></i></span>
                </div>
            </div>
            <div class="setting-body">
                <div class="col-sm-12 empty-cart-cls text-center"
                     style="padding-top:20%; height: 100vh; text-align:center" *ngIf="!products.length">
                    <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
                    <h3 class="text-center" style="text-align:center !important;"><strong>Your Cart is Empty</strong>
                    </h3>
                    <h4 class="text-center" style="text-align:center !important;">Let us add something :)</h4>
                </div>
                <div class="row" *ngIf="products.length">
                    <div class="col-sm-12 cart-product">
                        <table width="100%">
                            <tr *ngFor="let product of products">
                                <td style="margin-left: 15px; border-bottom: 1px solid #eaeaec !important">
                                    <table width="100%">
                                        <tr>
                                            <td class="cart-product-td hover-item" width="20%"
                                                (click)="navigateToProduct(product.name, product.id)">
                                                <a>
                                                    <img width="75px" height="75px"
                                                         [src]="imageURL+ (product.searchImage === undefined ? product.si: product.searchImage)"/>
                                                </a>
                                            </td>
                                            <td class="cart-product-td hover-item" width="70%"
                                                (click)="navigateToProduct(product.name, product.id)">
                                                <table>
                                                    <tr>
                                                        <td style="font-weight: bold; text-align: left">
                                                            {{product.name}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="font-size: 10px">
                                                            <span><b>Color:</b> {{product.color.name}} | </span> <span><b>Size:</b> {{product.size.name}} </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="text-transform: capitalize; text-align: left">
                                                            Qty: {{(product.qty === null || product.qty === undefined) ? product.quantity : product.qty}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span *ngIf="product.bdi">
                                                                <del>{{ (product.mp) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}</del>
                                                                {{ (product.bdp) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                                            </span>
                                                            <span *ngIf="!product.bdi">
                                                                <del>{{ (product.mp) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}</del>
                                                                {{ (product.sp) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td class="cart-product-td">
                                                <table>
                                                    <tr>
                                                        <td class="cart-item-close" title="Remove from cart" (click)="removeItem(product)">
                                                            x
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <i title="Add to wishlist" class="fa fa-heart-o wishlist-item-close" (click)="addToWishlist(product)"></i>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <!--<table class="table cart-table table-responsive-xs">
                            <thead>
                            <tr class="table-head">
                                <th scope="col">image</th>
                                <th scope="col">product name</th>
                                <th scope="col">price</th>
                                <th scope="col">quantity</th>
                                <th scope="col">action</th>
                                <th scope="col">total</th>
                            </tr>
                            </thead>
                            <tbody *ngFor="let product of products">
                            <tr>
                                <td>
                                    <a [routerLink]="['/shop/product/', product?.name?.replace(' ', '-'), product.pv, product.ac]">
                                        <img [src]="imageURL+product.searchImage" [alt]="imageURL+product.searchImage">
                                    </a>
                                </td>
                                <td>
                                    <a [routerLink]="['/shop/product/', product?.name?.replace(' ', '-')]">{{ product?.name | titlecase }}</a>
                                    <div class="mobile-cart-content row">
                                        <div class="col-xs-3">
                                            <div class="qty-box">
                                                <div class="input-group">
                                                    <input type="text" name="qty" class="form-control input-number"
                                                           disabled [(ngModel)]="product.qty">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-3">
                                            <h2 class="td-color">
                                                {{ (product.sp) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                            </h2>
                                        </div>
                                        <div class="col-xs-3">
                                            <h2 class="td-color">
                                                <a (click)="removeItem(product)" class="icon">
                                                    <i class="ti-close"></i>
                                                </a>
                                            </h2>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <h2>
                                        {{ (product.sp) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                    </h2>
                                </td>
                                <td>
                                    <div class="qty-box">
                                        <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus"
                                                (click)="decrement(product)">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                            <input type="text" name="quantity" class="form-control input-number" disabled
                                                   [(ngModel)]="product.qty">
                                            <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus"
                                                (click)="increment(product)">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a href="javascript:void(0)" (click)="addToWishlist(product)" class="icon">
                                        <i class="ti-heart"></i>
                                    </a> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="javascript:void(0)" (click)="removeItem(product)" class="icon">
                                        <i class="ti-close"></i>
                                    </a>
                                </td>
                                <td>
                                    <h2 class="td-color">
                                        {{ ((product.sp) * product.qty) * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                                    </h2>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table cart-table table-responsive-md">
                            <tfoot>
                            <tr>
                                <td>total price :</td>
                                <td>
                                    <h2> {{ getTotal | async | currency:productService?.Currency.currency:'symbol'}}</h2>
                                </td>
                            </tr>
                            </tfoot>
                        </table>-->
                    </div>
                    <div class="col-sm-12 cart-price">
                        <table width="100%">
                            <tr>
                                <td width="50%" class="text-center cart-price-value">
                                    <h2> {{ grandTotal | currency:productService?.Currency.currency:'symbol'}}</h2>
                                </td>
                                <td class="text-right">
                                    <button type="submit" class="btn-solid btn" (click)="showcheckout()">
                                        Place Order
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- theme setting -->
    <div class="sidebar-btn dark-light-btn">
        <div class="dark-light">
            <div class="theme-layout-version" (click)="customizeLayoutDark()">Dark</div>
        </div>
    </div>

</div>
