import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-footer-one',
    templateUrl: './footer-one.component.html',
    styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

    @Input() class: string = 'footer-light'; // Default class
    @Input() themeLogo: string = 'assets/images/icon/logo-single.png'; // Default Logo
    @Input() newsletter: boolean = true; // Default True
    emailModal = null;
    public today: number = Date.now();

    constructor(public toastr: ToastrService) {
    }

    ngOnInit(): void {
    }

    subscribeToNewsLetter() {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(String(this.emailModal).toLowerCase())) {
            this.toastr.success('Thanks for subscribing!');
        } else{
            this.toastr.error('Please enter a valid email to subscribe!');
        }
    }
}
