import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';

export class TranslationService implements TranslateLoader {
    constructor(private http: HttpClient) {
    }

    getTranslation(lang: string): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/setting/trans?lang=' + lang);
    }
}
