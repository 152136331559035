<!--modal popup start-->
<ng-template class="theme-modal" #newsletter let-modal>
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>-->
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="modal-bg">
                            <h3>{{selectedLanguage.applyHeading}}</h3>
                            <!--                            <h6>Select Language according to your preference</h6>-->
                            <div class="offer-content">
                                <div class="row">
                                    <div class="lang col-lg-6 col-md-6" *ngFor="let lang of languages"  (click)="selectLanguage(lang)">
                                        <div class="langImgIcon"><img class=""
                                                                      [src]="lang.icon"
                                                                      style=""></div>
                                        <div class="langName">{{lang.dispText}}</div>
                                        <div class="customRadio icon" name="langradio" value="hi">
                                            <img class="tickIcon icon img-loaded" *ngIf="lang.selected"
                                                 src="assets/images-custom/language/tick.png"
                                                 style=""/>
                                            <img class="nonTickIcon icon img-loaded" *ngIf="!lang.selected"
                                                 src="assets/images-custom/language/nontick.png"
                                                 style=""></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="modal-bg">
                            <div class="offer-content"><img src="assets/images/offer-banner-1.png" class="img-fluid"
                                                            alt="">
                                <h2>newsletter</h2>
                                <form action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
                                      class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form"
                                      name="mc-embedded-subscribe-form" target="_blank">
                                    <div class="form-group mx-sm-3">
                                        <input type="text" class="form-control" name="EMAIL" id="mce-EMAIL"
                                               placeholder="Enter your email" required="required">
                                        <button type="submit" class="btn btn-solid" id="mc-submit">subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->
