// ProductCartWishlist
export class ProductCartWishlist {
    id?: number;
    title?: string;
    name?: string;
    dor: number;
    dp: number;
    qty: number;
    sp: number;
    di: number;
    mp: number;
    pv: number;
    vi: number;
    mi: string;
    si: string;
    oi: string;
    qtyToAdd: number;
    bdi: number;
    ubid: number;
    bdp: number;
    av: any;

    constructor(id, name, dor, dp, qty, sp, di, mp, pv, vi, mi, si, oi, qtyToAdd, bdi, ubid, bdp, av) {
        this.id = id;
        this.name = name;
        this.dor = dor;
        this.dp = dp;
        this.qty = qty;
        this.sp = sp;
        this.di = di;
        this.mp = mp;
        this.pv = pv;
        this.vi = vi;
        this.mi = mi;
        this.si = si;
        this.oi = oi;
        this.qtyToAdd = qtyToAdd;
        this.bdi = bdi;
        this.ubid = ubid;
        this.bdp = bdp;
        this.av = av;
    }
}
