<div class="icon-nav" style="font-size: 16px">
    <ul>
        <li class="mobile-menu-bar" id="search-widgets1">
            <div (click)="leftMenuToggle()">
                <i class="fa fa-bars"></i>
            </div>
        </li>
        <li>
            <img class="invert-color" src="assets/icons/products/madeinindia.png" height="32px"/>
        </li>
        <li class="onhover-div mobile-search search-widgets" id="search-widgets">
            <div (click)="searchToggle()">
                <img src="assets/images/icon/search.png" width="18px" class="img-fluid" alt="">
                <i class="fa fa-search" aria-hidden="true"></i> <span
                    class="menu-text">{{'menu.search'|translateFallback: {default: "Search"} }}</span>
            </div>
            <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                <div>
                    <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                    <div class="overlay-content">
                        <div class="container">
                                <div class="row">

                                    <div class="col-xl-10">
                                        <div class="form-group">
                                            <input type="text" [(ngModel)]="searchText" class="form-control" id="exampleInputPassword1"
                                                   placeholder="Search a Product">
                                        </div>
                                    </div>
                                    <div class="col-xl-2">
                                        <button type="button" (click)="searchProduct()" class="btn btn-primary"><!--<i class="fa fa-search"></i>-->
                                            Search
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <!--<li class="onhover-div mobile-setting">
            <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
                <i class="ti-settings"></i></div>
        </li>-->
        <li class="onhover-div compare mobile-hide" (click)="chooseLanguage()">
            <img src="assets/images/icon/language1.png" width="18px" class="img-fluid invert-color" alt=""> <span
                class="menu-text">{{selectLang}}</span>
            <!--<ul class="menu">
                <li><a [routerLink]="['/shop/collection']" [queryParams]="{ category: 'c:13,14,15'}">{{selectLang}}</a>
                    <ul>
                        <li>adasd</li>
                    </ul>
                </li>
            </ul>-->
            <!--<i class="fa fa-globe" aria-hidden="true"></i> {{selectLang}}
            <ul class="onhover-show-div">
                <li>
                    <a data-lng="en">
                        Login
                    </a>
                </li>
                <li>
                    <a data-lng="es">
                        Logout
                    </a>
                </li>
            </ul>-->
        </li>
        <li class="mobile-hide">
            <div class="cursor-pointer" [routerLink]="['/user/dashboard']" *ngIf="isLoggedIn">
                <i class="fa fa-user" style="font-size: 20px"></i> <!--<span class="menu-text">Account</span>-->
            </div>
            <div class="cursor-pointer" [routerLink]="['/user/login']" *ngIf="!isLoggedIn">
                <span class="menu-text">{{'menu.loginRegister' | translateFallback: {default: "Login/Register"} }}</span>
            </div>
        </li>
        <li class="mobile-hide">
            <div class="cursor-pointer" [routerLink]="['/shop/wishlist']">
                <!--<a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Wishlist</a>-->
                <i class="fa fa-heart faa-pulse animated" style="font-size: 18px; color: red"></i> <sup
                    *ngIf="wishlistItems.length>0"> {{wishlistItems.length}}</sup>
                <!--<span class="menu-text">Wishlist</span>-->
            </div>
        </li>
        <li class="cart">
            <div class="cursor-pointer" (click)="openCart()">
                <i class="fa fa-shopping-cart" style="font-size: 18px;"></i> <span
                    class="menu-text">{{'menu.cart' | translateFallback: {default: "Cart"} }} ({{ cartItems.length }}
                )</span>
            </div>
        </li>
        <!--<li class="onhover-div mobile-cart">
            <div>
                <a (click)="openCart()">
                    <img src="assets/images/icon/cart.png" width="18px" class="img-fluid" alt="">
                    <i class="ti-shopping-cart"></i>
                </a>
            </div>
            <span class="cart_qty_cls">{{ products.length }}</span>
            <ul class="show-div shopping-cart" *ngIf='!products.length'>
                <h5>Your cart is currently empty.</h5>
            </ul>
            <ul class="show-div shopping-cart" *ngIf='products.length'>
                <li *ngFor="let product of products">
                    <div class="media">
                        <a [routerLink]="['/shop/product4/', product?.name?.replace(' ', '-')]">
                            <img class="mr-3" [src]="imageURL + product?.searchImage"
                                 [alt]="imageURL + product?.searchImage">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/shop/product4/', product?.name?.replace(' ', '-')]">
                                <h4>{{ product?.title }}</h4>
                            </a>
                            <h4>
                <span>
                  {{ product?.quantity }} x
                    {{ product?.sellingPrice * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="close-circle" (click)="removeItem(product)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                </li>
                <li>
                    <div class="total">
                        <h5>subtotal :
                            <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span>
                        </h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
                        <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
                    </div>
                </li>
            </ul>
        </li>-->
        <li class="mobile-more-bar" id="search-widgets2">
            <div (click)="leftMenuToggle()">
                <i class="fa fa-bars"></i>
            </div>
        </li>
    </ul>
</div>
<!--<app-language-selection #LanguageSelectionModal></app-language-selection>-->
