import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjE1MDE3NDIyLCJleHAiOjE2MTU4ODE0MjJ9.5vZN2VDAWFRWlFeyQRU-APtwoT7PxEz4ZyBOPDN4V8lXW3z5_5PHC-e-rqiGzgZItwTFZg-cC1pKa1vJs1-qkA',
        })
    };

    constructor(private http: HttpClient) {
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return from(result);
        };
    }

    public getSettings(category): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/setting/app/?lang=' + category.lang);
    }

    public getCategory(category): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/setting/fe/category?lang=' + category.shortCode);
    }

    public getLanguages(): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/app/langs');
    }

    public getTopBanner(): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/setting/fe/banner/top');
    }

    public getMainBanner(category): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/setting/fe/banner/main?lang=' + category.lang);
    }

    public getFilters(filter): Observable<any> {
        if (typeof filter.map !== 'undefined') {
            return this.http.post(environment.endpoint + '/gn/pd/search?lang=' + filter.lang + '&f=p:' + filter.attributes + '-s:' + filter.secondary + '-' + filter.category + '&search=' + filter.search + '&pageSize=20&sortDir=ASC&sortField=name&start=0&mip=' + filter.mip + '&map=' + filter.map, filter);
        } else {
            return this.http.post(environment.endpoint + '/gn/pd/search?lang=' + filter.lang + '&f=p:' + filter.attributes + '-s:' + filter.secondary + '-' + filter.category + '&search=' + filter.search + '&pageSize=20&sortDir=ASC&sortField=name&start=0', filter);
        }
    }

    public getProduct(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/product/dt/' + filter.id + '/list?lang=' + filter.lang + '&ac=' + filter.ac);
    }

    public getProductByAC(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/product/dp/' + filter.id + '/list?lang=' + filter.lang + '&ac=' + filter.ac);
    }


    public getCartProducts(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/user/cart?lang=' + filter.lang, this.httpOptions);
    }

    public addCartProducts(filter): Observable<any> {
        return this.http.put(environment.endpoint + '/gn/user/cart', filter, this.httpOptions);
    }

    public deleteCartProducts(filter): Observable<any> {
        return this.http.delete(environment.endpoint + '/gn/user/cart/item/' + filter.id, this.httpOptions);
    }

    public getWishList(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/user/wishlist?lang=' + filter.lang, this.httpOptions);
    }

    public addWishList(filter): Observable<any> {
        return this.http.put(environment.endpoint + '/gn/user/wishlist', filter, this.httpOptions);
    }

    public deleteWishList(filter): Observable<any> {
        return this.http.delete(environment.endpoint + '/gn/user/wishlist/item/' + filter, this.httpOptions);
    }

    public getUserAddresses(): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/user/profile/address', this.httpOptions);
    }

    public addAddress(filter): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/user/profile/address', filter, this.httpOptions);
    }

    public updateDefaultAddress(filter): Observable<any> {
        return this.http.put(environment.endpoint + '/gn/user/profile/address/default/' + filter.id, filter, this.httpOptions);
    }

    public getCountry(): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/country?&pageSize=10&sortDir=ASC&sortField=name&start=0&search=');
    }

    public getState(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/country/states/' + filter + '?&pageSize=40&sortDir=ASC&sortField=name&start=0&search=');
    }

    public getCity(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/state/cities/' + filter + '?&pageSize=1000&sortDir=ASC&sortField=name&start=0&search=');
    }

    public getCityDetailsByName(search): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/city?search=' + search);
    }

    public getOrders(filter): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/user/orders?&pageSize=10&sortDir=DESC&sortField=date&start=0&search=', filter, this.httpOptions);
    }

    public getOrderDetail(filter): Observable<any> {
        return this.http.get(environment.endpoint + '/gn/user/order/' + filter.id + '?lang=' + filter.lang, this.httpOptions);
    }

    public startBargaining(variantId, bargainingId, levelId): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/product/bargain/start/' + variantId + '/' + bargainingId + '/' + levelId, {});
    }

    public restartBargaining(variantId, bargainingId, levelId): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/product/bargain/restart/' + variantId + '/' + bargainingId + '/' + levelId, {});
    }

    public getUserProductVariantBargainingAttempt(variantId): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/product/bargain/check/' + variantId, {});
    }

    public placeuserBargain(bargainId, price, levelId): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/product/bargain/place/' + bargainId + '/' + levelId + '?price=' + price, {});
    }

    getDeliveryOptions() {
        return this.http.get(environment.endpoint + '/gn/delivery/options', this.httpOptions);
    }
}
