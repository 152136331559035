import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ProductService} from '../../services/product.service';
import {Product} from '../../classes/product';
import {NavService} from '../../services/nav.service';
import {AppService} from '../../services/app.service';
import {HomeService} from '../../services/home.service';
import {LanguageSelectionComponent} from '../modal/language-selection/language-selection.component';
import {TokenStorageService} from '../../../custom/services/token-storage.service';
import {ProductCartWishlist} from '../../classes/cartwishlist';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public products: Product[] = [];
    public wishlistItems: ProductCartWishlist[] = [];
    public settings: any = {};
    public cartItems: ProductCartWishlist[] = [];
    public search: boolean = false;
    public imageURL = '';
    @ViewChild('languageSelection') LanguageSelectionModal: LanguageSelectionComponent;
    public selectLang: string;
    isLoggedIn = false;

    public languages = [{
        name: 'English',
        code: 'en'
    }, {
        name: 'Hindi',
        code: 'hi'
    }];

    public currencies = [{
        name: 'Euro',
        currency: 'EUR',
        price: 0.90 // price of euro
    }, {
        name: 'Rupees',
        currency: 'INR',
        price: 70.93 // price of inr
    }, {
        name: 'Pound',
        currency: 'GBP',
        price: 0.78 // price of euro
    }, {
        name: 'Dollar',
        currency: 'USD',
        price: 1 // price of usd
    }];
    searchText: any;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private translate: TranslateService, public navServices: NavService,
                public productService: ProductService, private appService: AppService,
                public homeService: HomeService, public tokenService: TokenStorageService,
                public dialog: MatDialog, public router: Router) {
    }

    ngOnInit(): void {
        const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
        const categoryData = {
            lang: selectedLanguage === undefined ? 'en' : selectedLanguage[0].shortCode
        };
        this.selectLang = selectedLanguage[0].dispText;
        this.navServices.cartItems.subscribe(response => {
            this.cartItems = response;
        });
        this.navServices.wishlistItems.subscribe(response => {
            this.wishlistItems = response;
        });
        this.navServices.settings.subscribe(response => {
            this.settings = response;
            this.imageURL = this.settings.imageURL;
        });
        this.navServices.loggedIn.subscribe(response => {
            this.isLoggedIn = response;
        });
        /*this.homeService.getWishList(categoryData).subscribe((products) => {
            this.navServices.wishlistItems.next(products);
        });*/
        this.homeService.getCartProducts(categoryData).subscribe((products) => {
            this.navServices.cartItems.next(products);
        }, error => {
            if (localStorage.cartItems !== undefined) {
                if (this.cartItems.length === 0) {
                    this.cartItems = JSON.parse(localStorage.cartItems);
                }
                this.navServices.localcartItems.next(JSON.parse(localStorage.cartItems));
            }
        });
        /*this.homeService.getSettings(categoryData).subscribe((settings) => {
            this.navServices.settings.next(settings);
        });*/
        if (this.tokenService.getToken()) {
            this.navServices.loggedIn.next(true);
        } else {
            this.navServices.loggedIn.next(false);
        }
    }

    searchToggle() {
        this.search = !this.search;
    }

    changeLanguage(code) {
        if (isPlatformBrowser(this.platformId)) {
            this.translate.use(code);
        }
    }

    get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    changeCurrency(currency: any) {
        this.productService.Currency = currency;
    }

    leftMenuToggle() {
        this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
    }

    openCart() {
        this.navServices.cartPopupOver.next(true);
    }

    chooseLanguage() {
        this.dialog.open(LanguageSelectionComponent, {
            width: '600px',
            disableClose: true,
            panelClass: 'custom-mat-dialog',
        });
    }

    searchProduct() {
        this.search = false;
        this.router.navigateByUrl('/shop/collection?category=&search=' + this.searchText);
    }
}
