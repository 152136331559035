import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ProductCartWishlist} from '../classes/cartwishlist';
import {TokenStorageService} from '../../custom/services/token-storage.service';
import {Router} from '@angular/router';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    megaMenu?: boolean;
    image?: string;
    active?: boolean;
    badge?: boolean;
    badgeText?: string;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})

export class NavService {

    constructor(public tokenStorage: TokenStorageService, public router: Router) {
    }

    public screenWidth: any;
    public leftMenuToggle: boolean = false;
    public mainMenuToggle: boolean = false;
    public accountMenuToggle: boolean = false;

    // Windows width
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }

    MENUITEMS: Menu[] = [
        {
            title: 'home', type: 'link', active: false, path: '/home',
        },
        {
            title: 'Innerwear', type: 'link', active: false, path: '/shop/collection',
        },
        {
            title: 'Sleepwear', type: 'link', active: false, path: '/home',
        },
        {
            title: 'Loungewear', type: 'link', active: false, path: '/home',
        }
    ];
    /*MENUITEMS: Menu[] = [
        {
            title: 'home', type: 'link', active: false, path: '/home',
        }, {
            title: 'Men', type: 'sub', megaMenu: true, active: false, children: [
                {
                    title: 'Innerwear', type: 'link', active: false, children: [
                        {
                            path: './collection?category=fashion', title: 'Vests', type: 'link', children: [
                                {path: './collection?category=fashion', title: 'Sleeveless Vests', type: 'link'},
                                {path: './collection?category=fashion', title: 'Sleeved Vests', type: 'link'},
                                {path: './collection?category=fashion', title: 'Gym Vests', type: 'link'}
                            ]
                        },
                        {path: './collection?category=fashion', title: 'Briefs', type: 'link'},
                        {path: './collection?category=fashion', title: 'Boxers', type: 'link'},
                        {path: './collection?category=fashion', title: 'Trunks', type: 'link'},
                        {
                            path: './collection?category=fashion', title: 'Thermal', type: 'link', children: [
                                {path: './collection?category=fashion', title: 'Top', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bottom', type: 'link'}
                            ]
                        },
                        {path: './collection?category=fashion', title: 'Shapewear', type: 'link'},
                    ]
                },
                {
                    title: 'Sleep & Lounge Wear', type: 'sub', active: false, children: [
                        {path: './collection?category=fashion', title: 'Pyjamas & Lounge Pants', type: 'link'},
                        {path: './collection?category=fashion', title: 'Pyjama Sets & Night Suits', type: 'link'},
                        {path: './collection?category=fashion', title: 'Lounge Shorts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Sleep Robes', type: 'link'}
                    ]
                }, {
                    title: 'Apparel Tops', type: 'sub', active: false, children: [
                        {path: './collection?category=fashion', title: 'Tank Tops', type: 'link'},
                        {path: './collection?category=fashion', title: 'T-shirts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Polos', type: 'link'},
                        {path: './collection?category=fashion', title: 'Sweat shirts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Jackets & Hoodies', type: 'link'}
                    ]
                }, {
                    title: 'Apparel Bottoms', type: 'sub', active: false, children: [
                        {path: './collection?category=fashion', title: 'Boxer shorts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Shorts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Bermudas', type: 'link'},
                        {path: './collection?category=fashion', title: 'Joggers', type: 'link'},
                        {path: './collection?category=fashion', title: 'Track pants', type: 'link'}
                    ]
                }
            ]
        },
        {
            title: 'Women', type: 'sub', megaMenu: true, active: false, children: [
                {
                    path: './collection?category=fashion', title: 'Lingerie', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Bras', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Everyday Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'T-shirt Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Active Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Beginners Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Strapless Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Sleep Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Nursing Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Plus size Bras', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Panties', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Bikinis', type: 'link'},
                                {path: './collection?category=fashion', title: 'Boy Shorts', type: 'link'},
                                {path: './collection?category=fashion', title: 'Briefs', type: 'link'},
                                {path: './collection?category=fashion', title: 'Hipsters', type: 'link'},
                                {path: './collection?category=fashion', title: 'Shorties', type: 'link'},
                                {path: './collection?category=fashion', title: 'Full Briefs', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Baby Dolls & Chemises', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Camisoles & Tanks', type: 'sub'
                        }
                    ]
                }, {
                    path: './collection?category=fashion', title: 'Lingerie', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Shapewear', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Control Panties', type: 'link'},
                                {path: './collection?category=fashion', title: 'Thigh Slimmers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Tops', type: 'link'},
                                {path: './collection?category=fashion', title: 'Waist Cinchers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bodysuits', type: 'link'},
                                {path: './collection?category=fashion', title: 'Control Slips', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Accessories', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Breast Petals', type: 'link'},
                                {path: './collection?category=fashion', title: 'Pads & Enhancers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Straps', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bra Extenders', type: 'link'},
                            ]
                        }
                    ]
                }, {
                    path: './collection?category=fashion', title: 'Sleep & Lounge', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Tops', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Bottoms', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Sets', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Robes', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Nightgowns & Sleepshirts', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Onesies', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Nighties & Nightdresses', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Nightwear Sets', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Pyjama Sets', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Pyjamas & Lounge Pants', type: 'sub'
                        }
                    ]
                }, {
                    path: './collection?category=fashion', title: 'Maternity', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Ethnic Wear', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Kurtas & Kurtis', type: 'link'},
                                {path: './collection?category=fashion', title: 'Salwar Suits', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bottom Wear', type: 'link'}
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Western Wear', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Tops, T-Shirts & Shirts', type: 'link'},
                                {path: './collection?category=fashion', title: 'Dresses', type: 'link'},
                                {path: './collection?category=fashion', title: 'Trousers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Jeans', type: 'link'},
                                {path: './collection?category=fashion', title: 'Leggins', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Lingerie', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Nursing Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Panties', type: 'link'},
                                {path: './collection?category=fashion', title: 'Belly Bands & Supports', type: 'link'},
                                {path: './collection?category=fashion', title: 'Camisoles & Tanks', type: 'link'},
                                {path: './collection?category=fashion', title: 'Pantyhose & Stockings', type: 'link'},
                                {path: './collection?category=fashion', title: 'Shapewear', type: 'link'},
                            ]
                        }
                    ]
                }
            ]
        }, {
            title: 'Boys', type: 'sub', megaMenu: true, badge: false, badgeText: 'new', active: false, children: []
        }, {
            title: 'Girls', type: 'sub', megaMenu: true, badge: false, badgeText: 'new', active: false, children: []
        },
        {
            title: 'Offers', type: 'sub', megaMenu: false, badge: false, badgeText: 'new', active: false, children: []
        }
    ];*/

    LEFTMENUITEMS: Menu[] = [
        {
            title: 'home', type: 'sub', active: false, path: '/home',
        }, {
            title: 'Men', type: 'sub', megaMenu: true, active: false, children: [
                {
                    title: 'Innerwear', type: 'link', active: false, children: [
                        {
                            path: './collection?category=fashion', title: 'Vests', type: 'link', children: [
                                {path: './collection?category=fashion', title: 'Sleeveless Vests', type: 'link'},
                                {path: './collection?category=fashion', title: 'Sleeved Vests', type: 'link'},
                                {path: './collection?category=fashion', title: 'Gym Vests', type: 'link'}
                            ]
                        },
                        {path: './collection?category=fashion', title: 'Briefs', type: 'link'},
                        {path: './collection?category=fashion', title: 'Boxers', type: 'link'},
                        {path: './collection?category=fashion', title: 'Trunks', type: 'link'},
                        {
                            path: './collection?category=fashion', title: 'Thermal', type: 'link', children: [
                                {path: './collection?category=fashion', title: 'Top', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bottom', type: 'link'}
                            ]
                        },
                        {path: './collection?category=fashion', title: 'Shapewear', type: 'link'},
                    ]
                },
                {
                    title: 'Sleep & Lounge Wear', type: 'sub', active: false, children: [
                        {path: './collection?category=fashion', title: 'Pyjamas & Lounge Pants', type: 'link'},
                        {path: './collection?category=fashion', title: 'Pyjama Sets & Night Suits', type: 'link'},
                        {path: './collection?category=fashion', title: 'Lounge Shorts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Sleep Robes', type: 'link'}
                    ]
                }, {
                    title: 'Apparel Tops', type: 'sub', active: false, children: [
                        {path: './collection?category=fashion', title: 'Tank Tops', type: 'link'},
                        {path: './collection?category=fashion', title: 'T-shirts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Polos', type: 'link'},
                        {path: './collection?category=fashion', title: 'Sweat shirts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Jackets & Hoodies', type: 'link'}
                    ]
                }, {
                    title: 'Apparel Bottoms', type: 'sub', active: false, children: [
                        {path: './collection?category=fashion', title: 'Boxer shorts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Shorts', type: 'link'},
                        {path: './collection?category=fashion', title: 'Bermudas', type: 'link'},
                        {path: './collection?category=fashion', title: 'Joggers', type: 'link'},
                        {path: './collection?category=fashion', title: 'Track pants', type: 'link'}
                    ]
                }
            ]
        },
        {
            title: 'Women', type: 'sub', megaMenu: true, active: false, children: [
                {
                    path: './collection?category=fashion', title: 'Lingerie', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Bras', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Everyday Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'T-shirt Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Active Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Beginners Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Strapless Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Sleep Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Nursing Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Plus size Bras', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Panties', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Bikinis', type: 'link'},
                                {path: './collection?category=fashion', title: 'Boy Shorts', type: 'link'},
                                {path: './collection?category=fashion', title: 'Briefs', type: 'link'},
                                {path: './collection?category=fashion', title: 'Hipsters', type: 'link'},
                                {path: './collection?category=fashion', title: 'Shorties', type: 'link'},
                                {path: './collection?category=fashion', title: 'Full Briefs', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Baby Dolls & Chemises', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Camisoles & Tanks', type: 'sub'
                        }
                    ]
                }, {
                    path: './collection?category=fashion', title: 'Lingerie', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Shapewear', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Control Panties', type: 'link'},
                                {path: './collection?category=fashion', title: 'Thigh Slimmers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Tops', type: 'link'},
                                {path: './collection?category=fashion', title: 'Waist Cinchers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bodysuits', type: 'link'},
                                {path: './collection?category=fashion', title: 'Control Slips', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Accessories', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Breast Petals', type: 'link'},
                                {path: './collection?category=fashion', title: 'Pads & Enhancers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Straps', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bra Extenders', type: 'link'},
                            ]
                        }
                    ]
                }, {
                    path: './collection?category=fashion', title: 'Sleep & Lounge', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Tops', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Bottoms', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Sets', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Robes', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Nightgowns & Sleepshirts', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Onesies', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Nighties & Nightdresses', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Nightwear Sets', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Pyjama Sets', type: 'sub'
                        }, {
                            path: './collection?category=fashion', title: 'Pyjamas & Lounge Pants', type: 'sub'
                        }
                    ]
                }, {
                    path: './collection?category=fashion', title: 'Maternity', type: 'sub', children: [
                        {
                            path: './collection?category=fashion', title: 'Ethnic Wear', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Kurtas & Kurtis', type: 'link'},
                                {path: './collection?category=fashion', title: 'Salwar Suits', type: 'link'},
                                {path: './collection?category=fashion', title: 'Bottom Wear', type: 'link'}
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Western Wear', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Tops, T-Shirts & Shirts', type: 'link'},
                                {path: './collection?category=fashion', title: 'Dresses', type: 'link'},
                                {path: './collection?category=fashion', title: 'Trousers', type: 'link'},
                                {path: './collection?category=fashion', title: 'Jeans', type: 'link'},
                                {path: './collection?category=fashion', title: 'Leggins', type: 'link'},
                            ]
                        }, {
                            path: './collection?category=fashion', title: 'Lingerie', type: 'sub', children: [
                                {path: './collection?category=fashion', title: 'Nursing Bras', type: 'link'},
                                {path: './collection?category=fashion', title: 'Panties', type: 'link'},
                                {path: './collection?category=fashion', title: 'Belly Bands & Supports', type: 'link'},
                                {path: './collection?category=fashion', title: 'Camisoles & Tanks', type: 'link'},
                                {path: './collection?category=fashion', title: 'Pantyhose & Stockings', type: 'link'},
                                {path: './collection?category=fashion', title: 'Shapewear', type: 'link'},
                            ]
                        }
                    ]
                }
            ]
        }, {
            title: 'Boys', type: 'sub', megaMenu: true, badge: false, badgeText: 'new', active: false, children: []
        }, {
            title: 'Girls', type: 'sub', megaMenu: true, badge: false, badgeText: 'new', active: false, children: []
        },
        {
            title: 'Offers', type: 'sub', megaMenu: true, badge: true, badgeText: 'new', active: false, children: []
        }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
    leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
    cartPopupOver = new BehaviorSubject<boolean>(false);
    cartSlider = new BehaviorSubject<boolean>(false);
    wishlistItems = new BehaviorSubject<ProductCartWishlist[]>([]);
    cartItems = new BehaviorSubject<ProductCartWishlist[]>([]);
    localcartItems = new BehaviorSubject<ProductCartWishlist[]>([]);
    loggedIn = new BehaviorSubject<boolean>(false);
    settings = new BehaviorSubject<any>({});
    selectedMenu = new BehaviorSubject<any>('home');
    buyNow = new BehaviorSubject<any>(null);

    public logout(refresh?: boolean): void {
        localStorage.clear();
        this.tokenStorage.signOut();
        this.router.navigate(['/home'])
            .then(() => {
                window.location.reload();
            });
        /*if (refresh) {
            window.location.reload();
        }*/
    }
}
