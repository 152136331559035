import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) {
    }

    login(credentials): Observable<any> {
        return this.http.post(environment.endpoint + '/auth/login', {
            email: credentials.username,
            password: credentials.password
        }, httpOptions);
    }

    sendOTP(credentials): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/auth/sendOTP' + '?userId=' + credentials.username, {}, httpOptions);
    }

    verifyOTP(credentials): Observable<any> {
        return this.http.post(environment.endpoint + '/gn/auth/verifyOTP', {
            userId: credentials.username,
            otp: credentials.otp
        }, httpOptions);
    }

    refresh(token: string): Observable<any> {
        return this.http.get(environment.endpoint + '/auth/refreshtoken', {
            headers: new HttpHeaders({'Content-Type': 'application/json', isRefreshToken: 'true', Authorization: 'Bearer ' + token})
        });
    }

    register(user): Observable<any> {
        return this.http.post(environment.endpoint + '/auth/signup', {
            displayName: user.displayName,
            email: user.email,
            password: user.password,
            matchingPassword: user.matchingPassword,
            socialProvider: 'LOCAL'
        }, httpOptions);
    }
}
