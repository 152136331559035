import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppService} from '../shared/services/app.service';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

    public url: any;
    isMobile = false;
    isTablet = false;
    isDesktop = true;
    showMenu = false;

    constructor(private router: Router, public appService: AppService) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
                if (this.isDesktop && this.url !== '/user/login') {
                    this.showMenu = true;
                }
            }
        });
    }

    ngOnInit(): void {
        this.isMobile = this.appService.getLocalStorageItem('isMobile', true);
        this.isDesktop = this.appService.getLocalStorageItem('isDesktop', true);
        this.isTablet = this.appService.getLocalStorageItem('isTablet', true);
    }

}
