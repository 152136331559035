<!--footer section -->
<footer [class]="class">
    <div class="light-layout" *ngIf="newsletter">
        <div class="container">
            <section class="small-section border-section border-top-0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="subscribe">
                            <div>
                                <h4>{{'newsletter.heading' | translateFallback: {default: "KNOW IT ALL FIRST"} }}</h4>
                                <p>{{'newsletter.description' | translateFallback: {default: "Never Miss Anything From ULLU By Signing Up To Our Newsletter."} }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <form class="form-inline subscribe-form"
                              action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
                              target="_blank" method="post">
                            <div class="form-group mx-sm-3">
                                <input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
                                       [(ngModel)]="emailModal"
                                       [placeholder]="'newsletter.emailPlaceHolder' | translateFallback: {default: 'Enter your email'} ">
                            </div>
                            <button type="submit" (click)="subscribeToNewsLetter()"
                                    class="btn btn-solid">{{'newsletter.subscribe'|translateFallback: {default: "Subscribe"} }}</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col">
                    <div class="footer-title footer-mobile-title">
                        <h4>{{'brand.about-title' | translateFallback: {default: "About"} }}</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img [src]="themeLogo" alt="logo" height="50px">
                        </div>
                        <p>{{'brand.description' | translateFallback: {default: "Store Information"} }}</p>
                        <div class="footer-social">
                            <ul>
                                <li><a href="https://www.facebook.com/"><i class="fa fa-facebook"
                                                                           style="color: #4267B2"></i></a></li>
                                <!--<li><a href="https://plus.google.com/discover"><i class="fa fa-google-plus"></i></a>
                                </li>-->
                                <li><a href="https://twitter.com/"><i class="fa fa-twitter"
                                                                      style="color: #1DA1F2"></i></a></li>
                                <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"
                                                                            style="color: #405DE6"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4 class="d-sm-none d-md-block"> {{'footer.category.title'|translateFallback: {default: "About Us"} }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li>
                                    <a [routerLink]="['/user/cancellation-return-exchange']">Cancellation,Returns,Exchange
                                        Policy</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/user/delivery-policy']">Delivery Policy</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/user/privacy-policy']">Privacy Policy</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/user/terms-conditions']">Terms And Conditions</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/user/payment-policy']">Payment Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>{{'footer.category.title'|translateFallback: {default: "Categories"} }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">{{'footer.category.list.men'|translateFallback: {default: "Mens Fashion"} }}</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">{{'footer.category.list.women'|translateFallback: {default: "Womens Fashion"} }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>{{'help.title' | translateFallback: {default: "Help"} }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li>
                                    <a href="javascript:void(0)">{{'help.menu.track'|translateFallback: {default: "Track Order"} }}</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">{{'help.menu.shipReturn'|translateFallback: {default: "Ship & Return"} }}</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">{{'help.menu.secure'|translateFallback: {default: "Secure Shopping"} }}</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">{{'help.menu.gallery'|translateFallback: {default: "Gallery"} }}</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">{{'help.menu.contacts'|translateFallback: {default: "Contacts"} }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>{{'brand.store-title' | translateFallback: {default: "Store Information"} }}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li>{{'brand.address' | translateFallback: {default: "Ullu Digital, Lotus Business Park, 14th Floor, Veera Desai Industrial Estate, Andheri West, Mumbai, Maharashtra 400012"} }}</li>
                                <li>{{'brand.phone.heading' | translateFallback: {default: "Call Us"} }}
                                    : {{'brand.phone.number' | translateFallback: {default: "123-1234567"} }}</li>
                                <li>{{'brand.email.heading' | translateFallback: {default: "Email Us"} }}: <a
                                        href="javascript:void(0)">{{'brand.email.value' | translateFallback: {default: "support@ullu.store"} }}</a>
                                </li>
                                <li>{{'brand.fax.heading' | translateFallback: {default: "Fax"} }}
                                    : {{'brand.fax.number' | translateFallback: {default: "1231231231"} }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} ULLU</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->
