const apiURL = 'http://localhost:8443/api';
const oauthRedirect = 'http://localhost:4204/user/login';
export const environment = {
    production: false,
    instagram_token: 'INSTA_TOKEN',
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    endpoint: apiURL,
    imgEndpoint: 'https://stage-v1api.ullu.store/s3',
    appSecretKey: 'NimalTechFashDOT112',
    configuration: {
        itemSpecification: {
            separator: {
                line: '~#~$~',
                item: '^_#_^'
            }
        },
        paytm: {
            orderPrefix: 'ULLU-'
        }
    },
    oauth2: {
        auth: apiURL + '/auth',
        // redirectURL: '?redirect_uri=http://localhost:8081/login',
        google: {
            authURL: apiURL + '/oauth2/authorization/google?redirect_uri=' + oauthRedirect,
            clientId: '791035595585-23trnlamnaddqqeajs9t51k8ouje9e24.apps.googleusercontent.com'
        }, facebook: {
            authURL: apiURL + '/oauth2/authorization/facebook?redirect_uri=' + oauthRedirect,
            clientId: '791035595585-23trnlamnaddqqeajs9t51k8ouje9e24.apps.googleusercontent.com'
        }
    },
    pageSize: 10,
    pageSizeOptions: [10, 20, 30],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
