import {Component, HostListener, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AppService} from '../../services/app.service';
import {NavService} from '../../services/nav.service';
import {HomeService} from '../../services/home.service';
import {NavigationEnd, Router} from '@angular/router';
import {TokenStorageService} from '../../../custom/services/token-storage.service';
import {LanguageSelectionComponent} from '../../components/modal/language-selection/language-selection.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-header-four',
    templateUrl: './header-four.component.html',
    styleUrls: ['./header-four.component.scss']
})
export class HeaderFourComponent implements OnInit {

    @Input() class: string;
    @Input() themeLogo = 'assets/images/icon/logo-single.png'; // Default Logo
    @Input() topbar = true; // Default True
    @Input() sticky = false; // Default false
    isMobile = false;
    isTablet = false;
    isDesktop = true;
    public stick = false;
    cartItems = [];
    wishlistItems = [];
    hideHeader = false;
    showAccounts = false;
    isLoggedIn = false;
    userInfo: any;
    @ViewChild('languageSelection') LanguageSelectionModal: TemplateRef<any>;

    constructor(private appService: AppService, private navServices: NavService,
                private homeService: HomeService, private router: Router,
                public tokenService: TokenStorageService, public dialog: MatDialog) {
        this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                if (route.url.startsWith('/shop/product4') || route.url.startsWith('/shop/collection')) {
                    this.hideHeader = true;
                } else {
                    this.hideHeader = false;
                }
            }
        });
    }

    ngOnInit(): void {
        this.isMobile = this.appService.getLocalStorageItem('isMobile', true);
        this.isDesktop = this.appService.getLocalStorageItem('isDesktop', true);
        this.isTablet = this.appService.getLocalStorageItem('isTablet', true);
        this.navServices.cartItems.subscribe(response => {
            this.cartItems = response;
        });
        this.navServices.wishlistItems.subscribe(response => {
            this.wishlistItems = response;
        });
        this.navServices.loggedIn.subscribe(response => {
            this.isLoggedIn = response;
        });
        const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
        const categoryData = {
            lang: selectedLanguage[0].shortCode
        };
        this.homeService.getSettings(categoryData).subscribe((settings) => {
            this.navServices.settings.next(settings);
        });
        this.homeService.getCartProducts(categoryData).subscribe((products) => {
            this.navServices.cartItems.next(products);
        });
        this.homeService.getWishList(categoryData).subscribe((products) => {
            this.navServices.wishlistItems.next(products);
        });
        if (this.tokenService.getToken()) {
            this.navServices.loggedIn.next(true);
        } else {
            this.navServices.loggedIn.next(false);
        }
        this.userInfo = this.tokenService.getUser();
    }

    // @HostListener Decorator
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const scrollnumber = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollnumber >= 150 && window.innerWidth > 400) {
            this.stick = true;
        } else {
            this.stick = false;
        }
    }

    openCart() {
        this.navServices.cartPopupOver.next(true);
    }

    toggleAccountMenu() {
        this.showAccounts = !this.showAccounts;
    }

    logout() {
        this.tokenService.signOut();
        window.location.reload();
    }

    chooseLanguage() {
        this.toggleAccountMenu();
        this.dialog.open(LanguageSelectionComponent, {
            width: '600px',
            disableClose: true,
            panelClass: 'custom-mat-dialog',
        });
    }

    openPage(page) {
        if (this.isLoggedIn) {
            this.router.navigate([page]);
        } else {
            this.setLocalStorageOperation(page);
            this.router.navigate(['/user/login']);
        }
    }

    setLocalStorageOperation(page) {
        this.appService.setLocalStorageItem('opt', {
            fromPage: null,
            operation: 'navigate',
            data: null,
            action: null,
            toPage: page,
            miscParams: null
        }, true);
    }
}
