import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BarRatingModule} from 'ngx-bar-rating';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {TranslateModule} from '@ngx-translate/core';

// Header and Footer Components
import {FooterOneComponent} from './footer/footer-one/footer-one.component';

// Components
import {MenuComponent} from './components/menu/menu.component';
import {SettingsComponent} from './components/settings/settings.component';

// Modals Components
import {NewsletterComponent} from './components/modal/newsletter/newsletter.component';
import {QuickViewComponent} from './components/modal/quick-view/quick-view.component';
import {CartModalComponent} from './components/modal/cart-modal/cart-modal.component';
import {CartVariationComponent} from './components/modal/cart-variation/cart-variation.component';
import {VideoModalComponent} from './components/modal/video-modal/video-modal.component';
import {SizeModalComponent} from './components/modal/size-modal/size-modal.component';
import {AgeVerificationComponent} from './components/modal/age-verification/age-verification.component';

// Skeleton Loader Components
import {SkeletonProductBoxComponent} from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Layout Box
// Tap To Top
import {TapToTopComponent} from './components/tap-to-top/tap-to-top.component';

// Pipes
import {DiscountPipe} from './pipes/discount.pipe';
import {LanguageSelectionComponent} from './components/modal/language-selection/language-selection.component';
import {CartPopoverComponent} from '../shop/cart-popover/cart-popover.component';
import {CartSliderComponent} from '../shop/cart-slider/cart-slider.component';
import {HeaderFourComponent} from './header/header-four/header-four.component';
import {TranslateFBPipe} from './pipes/translatefallback.pipe';
import {UlluProductBoxComponent} from './components/product/ullu-product-box/ullu-product-box.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {MenuTwoComponent} from './components/menu-two/menu-two.component';

@NgModule({
    declarations: [
        HeaderFourComponent,
        FooterOneComponent,
        BreadcrumbComponent,
        MenuComponent,
        MenuTwoComponent,
        SettingsComponent,
        UlluProductBoxComponent,
        NewsletterComponent,
        LanguageSelectionComponent,
        QuickViewComponent,
        CartModalComponent,
        CartVariationComponent,
        VideoModalComponent,
        SizeModalComponent,
        AgeVerificationComponent,
        SkeletonProductBoxComponent,
        CartPopoverComponent,
        CartSliderComponent,
        TapToTopComponent,
        DiscountPipe,
        TranslateFBPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CarouselModule,
        BarRatingModule,
        LazyLoadImageModule.forRoot({
            // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
        }),
        NgxSkeletonLoaderModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CarouselModule,
        BreadcrumbComponent,
        MenuTwoComponent,
        BarRatingModule,
        LazyLoadImageModule,
        NgxSkeletonLoaderModule,
        TranslateModule,
        HeaderFourComponent,
        FooterOneComponent,
        UlluProductBoxComponent,
        NewsletterComponent,
        LanguageSelectionComponent,
        QuickViewComponent,
        CartModalComponent,
        CartVariationComponent,
        VideoModalComponent,
        SizeModalComponent,
        AgeVerificationComponent,
        SkeletonProductBoxComponent,
        CartPopoverComponent,
        CartSliderComponent,
        TapToTopComponent,
        DiscountPipe,
        TranslateFBPipe
    ]
})

export class SharedModule {
}
