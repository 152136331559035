import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Product} from '../classes/product';
import {HomeService} from './home.service';
import {each, findIndex, cloneDeep} from 'lodash';
import {AppService} from './app.service';
import {NavService} from './nav.service';
import {ProductCartWishlist} from '../classes/cartwishlist';

const state = {
    products: JSON.parse(localStorage.products || '[]'),
    wishlist: JSON.parse(localStorage.wishlistItems || '[]'),
    compare: JSON.parse(localStorage.compareItems || '[]'),
    cart: JSON.parse(localStorage.cartItems || '[]')
};

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    public Currency = {name: 'Rupees', currency: 'INR', price: 1}; // Default Currency
    public OpenCart = false;
    public Products;
    public isLoggedIn = false;

    constructor(private http: HttpClient, private appService: AppService,
                private toastrService: ToastrService, private homeService: HomeService,
                private navServices: NavService) {
        this.navServices.loggedIn.subscribe(response => {
            this.isLoggedIn = response;
        });
    }

    /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

    // Product
    private get products(): Observable<Product[]> {
        this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
        this.Products.subscribe(next => {
            localStorage.products = JSON.stringify(next);
        });
        return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage.products || '[]')));
    }

    // Get Products
    public get getProducts(): Observable<Product[]> {
        return this.products;
    }

    // Get Products By Slug
    public getProductBySlug(slug: string): Observable<Product> {
        return this.products.pipe(map(items => {
            return items.find((item: any) => {
                return item.title.replace(' ', '-') === slug;
            });
        }));
    }


    /*
      ---------------------------------------------
      ---------------  Wish List  -----------------
      ---------------------------------------------
    */

    // Get Wishlist Items
    public get wishlistItems(): Observable<ProductCartWishlist[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.wishlist);
            observer.complete();
        });
        return itemsStream as Observable<ProductCartWishlist[]>;
    }

    // Add to Wishlist
    public addItemToWishlist(variantId): any {
        if (this.isLoggedIn) {
            const wishlistItem = this.navServices.wishlistItems.getValue().find(item => item.pv === variantId);
            let reqObj = [];
            if (wishlistItem != null) {
                reqObj = [{
                    id: wishlistItem.id,
                    pv: variantId
                }];
            } else {
                reqObj = [{
                    id: null,
                    pv: variantId
                }];
            }
            this.homeService.addWishList(reqObj).subscribe((res) => {
                this.getUserWishlist();
                this.toastrService.success('Product has been added in wishlist.');
                return true;
            });
        } else {
            state.wishlist.push({
                ...variantId
            });
        }
    }

    public addToWishlist(product): any {
        const wishlistItem = state.wishlist.find(item => item.id === product.vi);
        if (!wishlistItem) {
            state.wishlist.push({
                ...product
            });
            const cartItems = [];

            const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
            // return true
            // console.log('product in wishlist ', state.wishlist);
            const wishlistItems = this.navServices.wishlistItems.getValue();
            if (wishlistItems.length > 0) {
                if (state.wishlist.length > 0) {
                    each(state.wishlist, (item) => {
                        const cartItemJSON = wishlistItems.find(items => items.vi === item.id);
                        let productJson = {};
                        if (cartItemJSON) {
                            productJson = {
                                id: cartItemJSON.id,
                                pv: item.id
                            };
                        } else {
                            productJson = {
                                id: null,
                                pv: item.id

                            };
                        }
                        cartItems.push(productJson);
                    });

                    this.homeService.addWishList(cartItems).subscribe((res) => {
                        this.getUserWishlist();
                        this.toastrService.success('Product has been added in wishlist.');
                        return true;
                    });
                }
            } else {
                if (state.wishlist.length > 0) {
                    each(state.wishlist, (item) => {
                        const productJson = {
                            id: null,
                            pv: item.id
                        };
                        cartItems.push(productJson);
                    });
                }
                this.homeService.addWishList(cartItems).subscribe((res) => {
                    this.getUserWishlist();
                    this.toastrService.success('Product has been added in wishlist.');
                    return true;
                });
            }
        }
    }

    getUserWishlist() {
        const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
        this.homeService.getWishList({
            lang: selectedLanguage[0].shortCode
        }).subscribe((products) => {
            this.navServices.wishlistItems.next(products);
        });
    }

    getUserCart() {
        const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
        const categoryData = {
            lang: selectedLanguage[0].shortCode
        };
        this.homeService.getCartProducts(categoryData).subscribe((products) => {
            this.navServices.cartItems.next(products);
        });
    }

    // Remove Wishlist items
    public removeWishlistItem(variantId: number): any {
        let id = null;
        this.navServices.wishlistItems.getValue().forEach(item => {
            if (item.pv === variantId) {
                id = item.id;
            }
        });
        this.homeService.deleteWishList(id).subscribe((res) => {
            // window.location.reload();
            this.getUserWishlist();
            this.toastrService.success('Product has been removed from wishlist.');
            return true;
        });
        // return true
    }

    /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

    // Get Compare Items
    public get compareItems(): Observable<Product[]> {
        const itemsStream = new Observable(observer => {
            observer.next(state.compare);
            observer.complete();
        });
        return itemsStream as Observable<Product[]>;
    }

    // Add to Compare
    public addToCompare(product): any {
        const compareItem = state.compare.find(item => item.id === product.id);
        if (!compareItem) {
            state.compare.push({
                ...product
            });
        }
        this.toastrService.success('Product has been added in compare.');
        localStorage.setItem('compareItems', JSON.stringify(state.compare));
        return true;
    }

    // Remove Compare items
    public removeCompareItem(product: Product): any {
        const index = state.compare.indexOf(product);
        state.compare.splice(index, 1);
        localStorage.setItem('compareItems', JSON.stringify(state.compare));
        return true;
    }

    /*
      ---------------------------------------------
      -----------------  Cart  --------------------
      ---------------------------------------------
    */

    // Get Cart Items
    public get cartItems(): Observable<ProductCartWishlist[]> {
        const itemsStream = new Observable(observer => {
            observer.next(this.navServices.cartItems.getValue());
            observer.complete();
        });
        return itemsStream as Observable<ProductCartWishlist[]>;
    }

    public addVariantToCart(product: ProductCartWishlist, bargainEnabled: boolean = false): any {
        if (this.isLoggedIn) {
            const cartItems = this.navServices.cartItems.getValue();
            const cartItemsToSave = [];
            const cartItemJSON = cartItems.find(items => items.pv === product.pv);
            if (cartItemJSON !== undefined) {
                cartItemsToSave.push({
                    id: cartItemJSON.id,
                    pv: product.pv,
                    qty: cartItemJSON.qty + product.qty,
                    bdi: bargainEnabled === true ? product.ubid : null
                });
            } else {
                cartItemsToSave.push({
                    id: null,
                    pv: product.pv,
                    qty: product.qty,
                    bdi: bargainEnabled === true ? product.ubid : null
                });
            }
            this.homeService.addCartProducts(cartItemsToSave).subscribe((res) => {
                localStorage.setItem('cartItems', JSON.stringify([]));
                this.toastrService.success('Product has been added to cart.');
                this.getUserCart();
                this.navServices.cartPopupOver.next(true);
            });
        } else {
            console.log(product);
            const cartItems = this.navServices.localcartItems.getValue();
            const cartItem = cartItems.find(item => item.pv === product.vi);
            if (cartItem) {
                cartItem.qty += 1;
            } else {
                cartItems.push(cloneDeep(product));
            }
            localStorage.setItem('cartItems', JSON.stringify(cartItems));
            this.navServices.localcartItems.next(cartItems);
            this.toastrService.success('Product has been added to cart.');
        }
    }

    // Add to Cart
    public addToCart(product: any, bargainEnabled: boolean = false): any {
        // TO ENABLE THE OLD ADD TO CART, UNCOMMENT THE BELOW AND USE IT
        /*const qty = product.qtyToAdd ? product.qtyToAdd : 1;
        if (this.isLoggedIn) {
            const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
            const cartItems = this.navServices.cartItems.getValue();
            const cartItemsToSave = [];
            if (cartItems.length > 0) {
                if (state.cart.length > 0) {
                    each(state.cart, (item) => {
                        const cartItemJSON = cartItems.find(items => items.pv === item.pv);
                        if (cartItemJSON) {
                            cartItemsToSave.push({
                                id: cartItemJSON.id,
                                pv: item.id,
                                qty: item.qty + qty,
                                bdi: bargainEnabled === true ? item.bdi : null
                            });
                        } else {
                            cartItemsToSave.push({
                                id: null,
                                pv: item.id,
                                qty: qty,
                                bdi: bargainEnabled === true ? item.bdi : null
                            });
                        }
                    });
                } else {
                    const cartItemJSON = cartItems.find(items => items.pv === product.id);
                    let qtyFinal = qty;
                    if (cartItemJSON) {
                        qtyFinal = cartItemJSON.qty + qty;
                    }
                    cartItemsToSave.push({
                        id: null,
                        pv: product.vi,
                        qty: qtyFinal,
                        bdi: bargainEnabled === true ? product.ubid : null
                    });
                }
            } else {
                if (state.cart.length > 0) {
                    each(state.cart, (item) => {
                        cartItemsToSave.push({
                            id: null,
                            pv: item.vi,
                            qty: qty,
                            bdi: bargainEnabled === true ? item.bdi : null
                        });
                    });
                } else {
                    console.log(product);
                    cartItemsToSave.push({
                        id: null,
                        pv: product.vi,
                        qty: qty,
                        bdi: bargainEnabled === true ? product.ubid : null
                    });
                }
            }
            this.homeService.addCartProducts(cartItemsToSave).subscribe((res) => {
                console.log('product in cart res', res);
                localStorage.setItem('cartItems', JSON.stringify([]));
                this.toastrService.success('Product has been added to cart.');
                this.getUserCart();
                this.navServices.cartPopupOver.next(true);
            });
        } else {
            const cartItem = state.cart.find(item => item.id === product.vi);
            const items = cartItem ? cartItem : product;

            if (cartItem) {
                cartItem.quantity += 1;
            } else {
                state.cart.push({
                    ...product,
                    quantity: 1
                });
            }
            localStorage.setItem('cartItems', JSON.stringify(state.cart));
            this.navServices.cartItems.next(state.cart);
        }*/
    }

    // Update Cart Quantity
    public updateCartQuantity(product: ProductCartWishlist, quantity: number): Product | boolean {
        return state.cart.find((items, index) => {
            if (items.id === product.id) {
                const qty = state.cart[index].qty + quantity;
                const stock = this.calculateStockCounts(state.cart[index], quantity);
                if (qty !== 0 && stock) {
                    state.cart[index].qty = qty;
                }
                localStorage.setItem('cartItems', JSON.stringify(state.cart));
                return true;
            }
        });
    }

    // Calculate Stock Counts
    public calculateStockCounts(product, quantity) {
        const qty = product.quantity + quantity;
        const stock = product.stock;
        /*if (stock < qty || stock == 0) {
          this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
          return false
        }*/
        return true;
    }

    // Remove Cart items
    public removeCartItem(product: ProductCartWishlist): any {
        /*const index = state.cart.indexOf(product);
        state.cart.splice(index, 1);
        localStorage.setItem('cartItems', JSON.stringify(state.cart));*/
        if (this.isLoggedIn) {
            this.homeService.deleteCartProducts(product).subscribe((res) => {
                // window.location.reload();
                this.toastrService.success('Cart item successfully removed.');
                this.getUserCart();
                return true;
            });
        } else {
            const cartElements = this.navServices.localcartItems.getValue();
            const position = findIndex(cartElements, (elem) => {
                return elem.pv === elem.pv;
            });
            cartElements.splice(position, 1);
            localStorage.setItem('cartItems', JSON.stringify(cartElements));
            this.navServices.localcartItems.next(cartElements);
            this.toastrService.success('Cart item successfully removed.');
        }
    }

    // Total amount
    public cartTotalAmount(): Observable<number> {
        return this.cartItems.pipe(map((product: ProductCartWishlist[]) => {
            return product.reduce((prev, curr: ProductCartWishlist) => {
                let price = curr.sp;
                if (curr.bdi) {
                    price = curr.bdp;
                }
                /*if(curr.discount) {
                  price = curr.price - (curr.price * curr.discount / 100)
                }*/
                return (prev + price * curr.qty) * this.Currency.price;
            }, 0);
        }));
    }

    /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

    // Get Product Filter
    public filterProducts(filter: any): Observable<Product[]> {
        return this.products.pipe(map(product =>
            product.filter((item: Product) => {
                if (!filter.length) {
                    return true;
                }
                const Tags = filter.some((prev) => { // Match Tags
                    if (item.tags) {
                        if (item.tags.includes(prev)) {
                            return prev;
                        }
                    }
                });
                return Tags;
            })
        ));
    }

    // Sorting Filter
    public sortProducts(products: Product[], payload: string): any {

        if (payload === 'ascending') {
            return products.sort((a, b) => {
                if (a.id < b.id) {
                    return -1;
                } else if (a.id > b.id) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'a-z') {
            return products.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                } else if (a.title > b.title) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'z-a') {
            return products.sort((a, b) => {
                if (a.title > b.title) {
                    return -1;
                } else if (a.title < b.title) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'low') {
            return products.sort((a, b) => {
                if (a.price < b.price) {
                    return -1;
                } else if (a.price > b.price) {
                    return 1;
                }
                return 0;
            });
        } else if (payload === 'high') {
            return products.sort((a, b) => {
                if (a.price > b.price) {
                    return -1;
                } else if (a.price < b.price) {
                    return 1;
                }
                return 0;
            });
        }
    }

    /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
    public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
        // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);

        // Paginate Range
        const paginateRange = 3;

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage: number;
        let endPage: number;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage < paginateRange - 1) {
            startPage = 1;
            endPage = startPage + paginateRange - 1;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }

        // calculate start and end item indexes
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems,
            currentPage,
            pageSize,
            totalPages,
            startPage,
            endPage,
            startIndex,
            endIndex,
            pages
        };
    }
}
