import {TranslateService} from '@ngx-translate/core';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'translateFallback'})
export class TranslateFBPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(value, interpolateParams?: any): any {
        let ret: string = this.translateService.instant(value, interpolateParams);
        ret = ret.replace(/{{(.*?)}}/g, '$1');
        if (ret === value) {
            return interpolateParams.default;
        }
        return ret;
    }
}
