<div *ngIf="!loader" [ngClass]="{'d-flex':layoutView==='list-view'}">
    <div class="row">
        <div class="col col-2 product-thumb-list" style="position: unset !important;" *ngIf="thumbnail">
            <div class="grid_thumb_img" style="padding-bottom: 8px;" [class.active]="selectedVariant?.si == image" *ngFor="let image of images">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">
                    <img [lazyLoad]="image" width="30px"/>
                </a>
            </div>
        </div>
        <div class="col col-10 img-wrapper d-flex justify-content-center align-items-center" style="padding-left: 5px;padding-right: 0px;">
            <div class="lable-block">
                <span class="lable3" *ngIf="product.new">new</span>
                <span class="lable4" *ngIf="product.sale">on sale</span>
            </div>
            <div class="front">
                <a [routerLink]="['/shop/product4/', product?.name?.replace(' ', '-'),selectedVariant.id+'-'+randNumber,'view']">
                    <img [defaultImage]="selectedVariant.si ? getImagePath(selectedVariant.si) : 'assets/images/product/placeholder.jpg'"
                         [lazyLoad]="ImageSrc ? ImageSrc : getImagePath(selectedVariant.si)"
                         class="img-fluid lazy-loading"
                         alt="{{ getImagePath(selectedVariant.si) }}"/>
                </a>
            </div>
            <div class="back" *ngIf="onHowerChangeImage">
                <a [routerLink]="['/shop/product4/', product?.name?.replace(' ', '-'), selectedVariant.id+'-'+randNumber,'view']">
                    <img [src]="ImageSrc ? ImageSrc : getImagePath(selectedVariant.si)" class="img-fluid lazy-loading"
                         alt="{{  getImagePath(selectedVariant.si)}}">
                </a>
            </div>
            <div class="quick-action">
                <div class="quick-action-element">
                    <a *ngIf="!addedToWishlist" href="javascript:void(0)" title="Add to Wishlist"
                       (click)="addToWishlist()">
                        <i class="fa fa-heart-o" aria-hidden="true"></i>
                    </a>
                    <a *ngIf="addedToWishlist" href="javascript:void(0)" title="Remove from Wishlist"
                       (click)="removeWishlistItem()">
                        <i class="fa fa-heart faa-pulse animated wishlist-heart" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="quick-action-element">
                    <a href="javascript:void(0)" title="Add to cart" (click)="addToCart()"
                       *ngIf="cartModal" [ngClass]="{'cart-added':cartCount>0}">
                        <i class="fa fa-shopping-cart"></i> <sup *ngIf="cartCount>0"><b>{{cartCount}}</b></sup>
                    </a>
                </div>
            </div>
            <div class="cart-info cart-wrap">
                <!--<a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
                   *ngIf="cartModal">
                    <i class="fa fa-shopping-cart"></i>
                </a>
                <a *ngIf="!addedToWishlist" href="javascript:void(0)" title="Add to Wishlist"
                   (click)="addToWishlist()">
                    <i class="fa fa-heart-o" aria-hidden="true"></i>
                </a>
                <a *ngIf="addedToWishlist" href="javascript:void(0)" title="Remove from Wishlist"
                   (click)="removeWishlistItem()">
                    <i class="fa fa-heart faa-pulse animated wishlist-heart" aria-hidden="true"></i>
                </a>-->
                <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                    <i class="ti-eye" aria-hidden="true"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="product-detail">
        <a [routerLink]="['/shop/product4/', product?.name?.replace(' ', '-'), selectedVariant.id+'-'+randNumber,'view']">
            <h6>{{ product?.name | titlecase }}</h6>
        </a>
        <h4 style="color: #FFFFFF">
            {{selectedVariant?.sp | currency:currency?.currency:'symbol'}}
            {{ selectedVariant?.dp * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
            <del *ngIf="selectedVariant?.mrp!==selectedVariant?.sp" style="font-size: 18px; font-weight: 500; color: #cfd4da">
                <span class="money">
                    {{ selectedVariant?.mrp * currency?.price | currency:currency?.currency:'symbol' }}
                </span>
            </del>
        </h4>
        <p *ngIf="layoutView === 'list-view'">
            {{selectedVariant.description}}
        </p>
        <!--<div class="d-flex flex-flow-wrap" style="padding-top: 8px; padding-bottom: 8px">
            <div class=" p-1 mr-1" *ngFor="let prodVariant of product.variants"
                 (click)="changeVariant(prodVariant)">
                <a style="width: 24px;height: 24px;font-size: 10px;line-height: 24px;background: #efefef;color: #1e1e21;opacity: 1;/*border-radius: 50%*/;display: block;padding-top: 0;text-align: center;"
                   href="javascript:void(0)" class="qty-box attrib-box"
                   [ngClass]="{'selected-attrib': selectedVariant.id === prodselectedVariant.id}">{{ prodselectedVariant.name | uppercase }}</a>
            </div>
        </div>-->
        <div>
            <div class="d-flex" style="overflow-x: scroll">
                <div class=" p-1 mr-1" *ngFor="let color of product['cmb']"
                     [title]="color.name"
                     (click)="changeVariantColor(color.dor, color.id)">
                    <a style="width: 24px;height: 24px;font-size: 14px;line-height: 36px;color: #1e1e21;opacity: 1;border-radius: 50%;display: block;"
                       href="javascript:void(0)" class="qty-box attrib-box color"
                       [style.background]="color.alt"
                       [ngClass]="{'selected-attrib-color': color.selected === true}"> </a>
                </div> &nbsp;
            </div>
            <div class="d-flex"  style="overflow-x: scroll">
                <div class=" p-1 mr-1" *ngFor="let size of product['smb']"
                     [title]="size.name"
                     (click)="changeVariantSize(size.dor, size.id)">
                    <a style="min-width: 24px; height: 24px;font-size: 10px;line-height: 24px;background: #efefef;color: #1e1e21;opacity: 1;/*border-radius: 50%*/;display: block;padding-top: 0;text-align: center;"
                       href="javascript:void(0)" class="qty-box attrib-box"
                       [ngClass]="{'selected-attrib-non-color': size.selected === true,'striked-element': size.qty === 0}">{{ size.alt | uppercase }}</a>
                </div> &nbsp;
            </div>
        </div>
        <div class="buttons row " style="padding-top: 10px;width: 98%;margin-left: 2%;text-align: center;">
            <div class="col col-6 text-center btn btn-outline btn-outline-custom btn-xs add-to-cart"
                 (click)="addToCart()">
                <!--<a class="btn btn-outline btn-xs add-to-cart"
                   (click)="addToCart(selectedVariant)"> <i class="fa fa-shopping-cart"></i> {{'cart.add' | translateFallback: {default: 'Add to cart'} }}</a>-->
                {{'cart.add' | translateFallback: {default: 'Add to cart'} }}
            </div>
            <div class="col col-6 text-center btn btn-solid btn-xs add-to-cart" (click)="buyNow()">
                <!--<a class="btn btn-solid btn-xs add-to-cart"
                   (click)="addToCart(selectedVariant)">{{'product.buyNow' | translateFallback: {default: 'Buy now'} }}</a>-->
                {{'product.buyNow' | translateFallback: {default: 'Buy now'} }}
            </div>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

