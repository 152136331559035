import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavService} from '../../shared/services/nav.service';
import {ProductService} from '../../shared/services/product.service';
import {AppService} from '../../shared/services/app.service';
import {HomeService} from '../../shared/services/home.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-cart-slider',
    templateUrl: './cart-slider.component.html',
    styleUrls: ['./cart-slider.component.scss']
})
export class CartSliderComponent implements OnInit {

    public layoutsidebar: boolean = false;
    public activeItem: string;
    public products: any = [];
    public imageURL = '';
    public showCartPopup = false;

    constructor(public productService: ProductService, private appService: AppService, public homeService: HomeService, public navService: NavService, public changeDetect: ChangeDetectorRef, private router: Router) {
        // this.productService.cartItems.subscribe(response => {this.products = response;console.log(response)});
    }

    ngOnInit(): void {
        const selectedLanguage: any = this.appService.getLocalStorageItem('lang', true);
        const categoryData = {
            lang: selectedLanguage[0].shortCode
        };
        this.navService.settings.subscribe((settings) => {
            this.imageURL = settings.asseturl;
        });

        this.homeService.getCartProducts(categoryData).subscribe((products) => {
            this.products = products;
        });
        this.navService.cartSlider.subscribe(response => {
            this.showCartPopup = response;
        });
    }

    public get getTotal(): Observable<number> {
        return this.productService.cartTotalAmount();
    }

    // Increament
    increment(product, qty = 1) {
        this.productService.updateCartQuantity(product, qty);
    }

    // Decrement
    decrement(product, qty = -1) {
        this.productService.updateCartQuantity(product, qty);
    }

    public removeItem(product: any) {
        this.productService.removeCartItem(product);
    }

    public addToWishlist(product: any) {
        this.productService.addToWishlist(product);
        this.productService.removeCartItem(product);
    }

    // Is active
    isActive(menuItem) {
        return this.activeItem === menuItem;
    }

    // Sidebar Toggle
    layoutSidebarToggle() {
        console.log(this.navService.cartSlider);
        this.navService.cartPopupOver.next(!this.navService.cartSlider.getValue());
    }

    // collapse
    opensettingcontent(val) {
        if (this.activeItem === val) {
            this.activeItem = '';
        } else {
            this.activeItem = val;
        }
    }

    // Layout Type
    customizeLayoutType(val) {
        if (val === 'rtl') {
            document.body.classList.remove('ltr');
            document.body.classList.add('rtl');
        } else {
            document.body.classList.remove('rtl');
            document.body.classList.add('ltr');
        }
    }

    // Set Theme color
    customizeThemeColor(event) {
        document.documentElement.style.setProperty('--theme-deafult', event.target.value);
    }

    customizeLayoutDark() {
        document.body.classList.toggle('dark');
    }

    showcheckout() {
        this.router.navigate(['/shop/checkout']);
    }
}
