<!-- header start -->
<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
    <div id="account-box" class="account-box" [class.openaccount]="showAccounts">
        <a href="javascript:void(0)" class="sidebar-overlay" (click)="toggleAccountMenu()"></a>
        <!--<div class="title d-flex justify-content-between">
            <span>My Account</span>
            <span (click)="toggleAccountMenu()"><i class="fa fa-times-circle"></i></span>
        </div>-->
        <div class="body">
            <div class="row user-details" [ngClass]="{'border-bottom-seperator':!isLoggedIn}">
                <div class="col col-3">
                    <img src="assets/images/icon/user.png" width="75"/>
                </div>
                <div class="col col-9" style="padding-left: 21px;padding-top: 8px;" *ngIf="isLoggedIn">
                    <div>Welcome</div>
                    <div>{{this.userInfo.displayName}}</div>
                    <div>{{this.userInfo.email === this.userInfo.displayName ? '' : this.userInfo.email}}</div>
                </div>
                <div class="col col-9" style="text-align: center; padding-top: 20px;" *ngIf="!isLoggedIn">
                    <a [routerLink]="['/user/login']" class="btn btn-solid btn-xs">Login/Register</a>
                </div>
            </div>
            <div class="logout-button" *ngIf="isLoggedIn" (click)="logout()">
                <a href="javascript:void(0)" class="btn btn-solid btn-xs">Logout</a>
            </div>
            <div class="account-menu">
                <div class="row" (click)="openPage('/shop/order')">
                    <div class="col col-1"><i class="fa fa-shopping-bag"></i></div>
                    <div class="col col-9 menu-title">Order History</div>
                    <div class="col col-1"><i class="fa fa-angle-right"></i></div>
                </div>
                <div class="row"  (click)="openPage('/shop/wishlist')">
                    <div class="col col-1"><i class="fa fa-heart"></i></div>
                    <div class="col col-9 menu-title">My Wishlist</div>
                    <div class="col col-1"><i class="fa fa-angle-right"></i></div>
                </div>
                <div class="row"  (click)="openPage('/user/address')" [routerLink]="['/user/address']">
                    <div class="col col-1"><i class="fa fa-map-marker" style="padding-left: 5px"></i></div>
                    <div class="col col-9 menu-title">Address Book</div>
                    <div class="col col-1"><i class="fa fa-angle-right"></i></div>
                </div>
                <div class="row" (click)="chooseLanguage()">
                    <div class="col col-1"><i class="fa fa-language" style="padding-left: 5px"></i></div>
                    <div class="col col-9 menu-title">Language</div>
                    <div class="col col-1"><i class="fa fa-angle-right"></i></div>
                </div>
                <!--<div class="row">
                    <div class="col col-1"><i class="fa fa-user-circle"></i></div>
                    <div class="col col-9 menu-title">Profile</div>
                    <div class="col col-1"><i class="fa fa-angle-right"></i></div>
                </div>-->
            </div>
        </div>
    </div>
    <div class="mobile-fix-option" *ngIf="isMobile">
        <div class="row" *ngIf="!hideHeader">
            <div class="col-3 action-icon" [routerLink]="['/home']">
                <i class="fa fa-home"></i>
            </div>
            <div class="col-2 action-icon">
                <i class="fa fa-search"></i>
            </div>
            <div class="col-2 action-icon" (click)="openCart()">
                <i class="fa fa-shopping-cart"></i> <sup style="padding-left: 2px">{{cartItems.length}}</sup>
            </div>
            <div class="col-2 action-icon" [routerLink]="['/shop/wishlist']">
                <i class="fa fa-heart"></i> <sup style="padding-left: 2px">{{wishlistItems.length}}</sup>
            </div>
            <div class="col-3 action-icon" (click)="toggleAccountMenu()">
                <i class="fa fa-list"></i>
            </div>
        </div>
        <div class="row" *ngIf="hideHeader">
            <div class="col-4 action-icon" [routerLink]="['/home']">
                <img [src]="themeLogo" alt="logo" height="20px"> ULLU
            </div>
            <div class="col-2 action-icon">
                <i class="fa fa-search"></i>
            </div>
            <div class="col-2 action-icon" (click)="openCart()">
                <i class="fa fa-shopping-cart"></i> <sup style="padding-left: 2px">{{cartItems.length}}</sup>
            </div>
            <div class="col-2 action-icon" [routerLink]="['/shop/wishlist']">
                <i class="fa fa-heart"></i> <sup style="padding-left: 2px">{{wishlistItems.length}}</sup>
            </div>
            <div class="col-2 action-icon" (click)="toggleAccountMenu()">
                <i class="fa fa-list"></i>
            </div>
        </div>
    </div>
    <div class="top-header top-header-dark2" *ngIf="topbar && isDesktop">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="header-contact">
                        <ul>
                            <li>{{'topbar-title' | translateFallback: {default: "Welcome to ULLU Store"} }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container layout3-menu mobile-hide" *ngIf="isDesktop">
        <div class="row">
            <div class="col-sm-12">
                <div class="main-menu">
                    <div class="menu-left around-border">
                        <div class="main-menu-right">
                            <app-menu-two></app-menu-two>
                        </div>
                    </div>
                    <div class="absolute-logo">
                        <div class="brand-logo">
                            <a [routerLink]="['/home']">
                                <img [src]="themeLogo" alt="logo" height="50px">
                            </a><br/>
                            <span class="font-weight-bolder">ULLU</span>
                            <br/>
                            <span [routerLink]="['/home']" class="slogan">KHAREDATEY RAHO!</span>
                        </div>
                    </div>
                    <div class="">
                        <div class="menu-right pull-right">
                            <div>
                                <app-settings></app-settings>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container layout3-menu" style="text-align: center; padding-top: 10px; padding-bottom: 10px;"
         *ngIf="isMobile && !hideHeader">
        <div>
            <div>
                <a [routerLink]="['/home']">
                    <img [src]="themeLogo" alt="logo" height="50px">
                </a><br/>
                <span class="font-weight-bolder">ULLU</span>
                <br/>
                <span [routerLink]="['/home']" class="slogan">KHAREDATEY RAHO!</span>
            </div>
        </div>
    </div>
</header>
<!-- header end -->
