<!--modal popup start-->
<!--<ng-template class="theme-modal" #languageSelection let-modal>-->
<div class="language-modal">
    <div class="modal-content">
        <div class="modal-body modal7">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="modal-bg">
                            <div class="langHeader">
                                <!-- <div class="langTitle"><h5>{{selectedLanguage.applyHeading}}</h5></div> -->
                                <div class="langTitle"><h3>Choose Language</h3></div>
                                <div class="langBtn" (click)="applyLanguage()">Apply</div>
                            </div>
                            <!--                            <h6>Select Language according to your preference</h6>-->
                            <div class="offer-content">
                                <div class="row langs">
                                    <div class="lang col-lg-4 col-md-6" *ngFor="let lang of languageList"
                                         (click)="selectLanguage(lang)" [ngClass]="{'lang-selected':lang.selected}">
                                        <div class="langImgIcon">
                                            <!-- <img class=""
                                                                      [src]="lang.icon"
                                                                      style=""> -->
                                            <i class="fa fa-language"></i>
                                                                    </div>
                                        <div class="langName">{{lang.dispText}}</div>
                                        <div class="customRadio icon" name="langradio" value="hi">
                                            <img class="tickIcon icon img-loaded" *ngIf="lang.selected"
                                                 src="assets/images-custom/language/tick.png"
                                                 style=""/>
                                            <img class="nonTickIcon icon img-loaded" *ngIf="!lang.selected"
                                                 src="assets/images-custom/language/nontick.png"
                                                 style=""></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--</ng-template>-->
<!--modal popup end-->
