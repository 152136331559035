import {
    Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
    Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('newsletter', {static: false}) NewsLetterModal: TemplateRef<any>;
    languages: any = [{
        icon: 'assets/images-custom/language/english.png',
        lang: 'English',
        dispText: 'English',
        selected: true,
        applyText: 'Apply',
        applyHeading: 'Choose Language',
    }, {
        icon: 'assets/images-custom/language/hindi.png',
        lang: 'Hindi',
        dispText: 'हिंदी',
        selected: false,
        applyText: 'लागू करें',
        applyHeading: 'भाषा चुनें / Choose Language',
    }, {
        icon: 'assets/images-custom/language/bangala.png',
        lang: 'Bangala',
        dispText: 'বাংলা',
        selected: false,
        applyText: 'প্রয়োগ করা',
        applyHeading: 'ভাষা নির্বাচন করুন / Choose Language',
    }, {
        icon: 'assets/images-custom/language/tamil.png',
        lang: 'Tamil',
        dispText: 'தமிழ்',
        selected: false,
        applyText: 'விண்ணப்பிக்கவும்',
        applyHeading: 'மொழியைத் தேர்வுசெய்க / Choose Language',
    }, {
        icon: 'assets/images-custom/language/telugu.png',
        lang: 'Telugu',
        dispText: 'తెలుగు',
        selected: false,
        applyText: 'వర్తించు',
        applyHeading: 'భాషను ఎంచుకోండి / Choose Language',
    }, {
        icon: 'assets/images-custom/language/marati.png',
        lang: 'Marati',
        dispText: 'मराठी',
        selected: false,
        applyText: 'अर्ज करा',
        applyHeading: 'भाषा निवडा',
    }, {
        icon: 'assets/images-custom/language/kannada.png',
        lang: 'Kannada',
        dispText: 'ಕನ್ನಡ',
        selected: false,
        applyText: 'ಅನ್ವಯಿಸು',
        applyHeading: 'ಭಾಷೆಯನ್ನು ಆರಿಸಿ / Choose Language',
    }, {
        icon: 'assets/images-custom/language/gujarati.png',
        lang: 'Gujarari',
        dispText: 'ગુજરાતી',
        selected: false,
        applyText: 'લાગુ કરો',
        applyHeading: 'ભાષા પસંદ કરો / Choose Language',
    }, {
        icon: 'assets/images-custom/language/punjabi.png',
        lang: 'Punjabi',
        dispText: 'ਪੰਜਾਬੀ',
        selected: false,
        applyText: 'ਲਾਗੂ ਕਰੋ',
        applyHeading: 'ਭਾਸ਼ਾ ਚੁਣੋ / Choose Language',
    }];
    selectedLanguage: any = this.languages[0];
    public closeResult: string;
    public modalOpen = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        if (localStorage.getItem('newsletter') !== 'true') {
            this.openModal();
        }
        localStorage.setItem('newsletter', 'false');
    }

    openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.NewsLetterModal, {
                size: 'lg',
                ariaLabelledBy: 'NewsLetter-Modal',
                centered: true,
                windowClass: 'theme-modal newsletterm NewsLetterModal'
            }).result.then((result) => {
                this.modalOpen = true;
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }

    selectLanguage(lang: any) {
        this.selectedLanguage = lang;
        _.forEach(this.languages, function(language: any) {
            if (language.lang === lang.lang) {
                language.selected = true;
            } else {
                language.selected = false;
            }
        });
    }

}
