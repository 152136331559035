import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AppEncryptDecryptService} from './app-encrypt-decrypt';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(private appEncryptDecryptService: AppEncryptDecryptService) {
    }

    public getLocalStorageItem(key: string, parsingNeeded: boolean) {
        const itemVal = localStorage.getItem(key);
        if (itemVal == null) {
            return null;
        }
        const decryptedValue = this.appEncryptDecryptService.get(environment.appSecretKey, itemVal);
        if (!parsingNeeded) {
            return decryptedValue;
        } else {
            return JSON.parse(decryptedValue);
        }
    }

    public setLocalStorageItem(key: string, value: any, parsingNeeded: boolean) {
        if (!parsingNeeded) {
            return localStorage.setItem(key, this.appEncryptDecryptService.set(environment.appSecretKey, value));
        } else {
            return localStorage.setItem(key, this.appEncryptDecryptService.set(environment.appSecretKey, JSON.stringify(value)));
        }
    }

    public getSessionStorageItem(key: string, parsingNeeded: boolean) {
        const itemVal = sessionStorage.getItem(key);
        if (itemVal == null) {
            return null;
        }
        const decryptedValue = this.appEncryptDecryptService.get(environment.appSecretKey, itemVal);
        if (!parsingNeeded) {
            return decryptedValue;
        } else {
            return JSON.parse(decryptedValue);
        }
    }

    public setSessionStorageItem(key: string, value: any, parsingNeeded: boolean) {
        if (!parsingNeeded) {
            return sessionStorage.setItem(key, this.appEncryptDecryptService.set(environment.appSecretKey, value));
        } else {
            return sessionStorage.setItem(key, this.appEncryptDecryptService.set(environment.appSecretKey, JSON.stringify(value)));
        }
    }

    public deleteLocalStorageItem(key: string) {
        localStorage.removeItem(key);
    }
}
