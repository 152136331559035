import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {ToastrModule} from 'ngx-toastr';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {ShopComponent} from './shop/shop.component';
import {PagesComponent} from './pages/pages.component';
import {ElementsComponent} from './elements/elements.component';
import {AppInterceptor} from './custom/utils/app-interceptor';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SortPipe} from './shared/pipes/sort.pipe';
import {NgOtpInputModule} from 'ng-otp-input';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslationService} from './shared/services/translation.service';
import {BlockUIModule} from 'ng-block-ui';

// AoT requires an exported function for factories
/*export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}*/

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslationService(http);
}

@NgModule({
    declarations: [
        AppComponent,
        ShopComponent,
        PagesComponent,
        ElementsComponent,
        SortPipe
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        NgxImageZoomModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            progressBar: false,
            enableHtml: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MatDialogModule,
        NgOtpInputModule,
        BlockUIModule.forRoot()
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptor,
            multi: true
        },
        SortPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
