import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {each} from 'lodash';
import {HomeService} from '../../../services/home.service';
import {AppService} from 'src/app/shared/services/app.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-language-selection',
    templateUrl: './language-selection.component.html',
    styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('languageSelection', {static: false}) LanguageSelectionModal: TemplateRef<any>;
    languagess: any = [{
        icon: 'assets/images-custom/language/english.png',
        lang: 'English',
        dispText: 'English',
        selected: true,
        applyText: 'Apply',
        code: 'en',
        applyHeading: 'Choose Language',
    }, {
        icon: 'assets/images-custom/language/hindi.png',
        lang: 'Hindi',
        dispText: 'हिंदी',
        selected: false,
        applyText: 'लागू करें',
        code: 'hi',
        applyHeading: 'भाषा चुनें / Choose Language',
    }, {
        icon: 'assets/images-custom/language/bangala.png',
        lang: 'Bangala',
        dispText: 'বাংলা',
        selected: false,
        applyText: 'প্রয়োগ করা',
        code: 'ba',
        applyHeading: 'ভাষা নির্বাচন করুন / Choose Language',
    }, {
        icon: 'assets/images-custom/language/tamil.png',
        lang: 'Tamil',
        dispText: 'தமிழ்',
        selected: false,
        applyText: 'விண்ணப்பிக்கவும்',
        code: 'ta',
        applyHeading: 'மொழியைத் தேர்வுசெய்க / Choose Language',
    }, {
        icon: 'assets/images-custom/language/telugu.png',
        lang: 'Telugu',
        dispText: 'తెలుగు',
        selected: false,
        applyText: 'వర్తించు',
        code: 'te',
        applyHeading: 'భాషను ఎంచుకోండి / Choose Language',
    }, {
        icon: 'assets/images-custom/language/marati.png',
        lang: 'Marati',
        dispText: 'मराठी',
        selected: false,
        applyText: 'अर्ज करा',
        code: 'ma',
        applyHeading: 'भाषा निवडा / Choose Language',
    }, {
        icon: 'assets/images-custom/language/kannada.png',
        lang: 'Kannada',
        dispText: 'ಕನ್ನಡ',
        selected: false,
        applyText: 'ಅನ್ವಯಿಸು',
        code: 'ka',
        applyHeading: 'ಭಾಷೆಯನ್ನು ಆರಿಸಿ / Choose Language',
    }, {
        icon: 'assets/images-custom/language/gujarati.png',
        lang: 'Gujarari',
        dispText: 'ગુજરાતી',
        selected: false,
        applyText: 'લાગુ કરો',
        code: 'gu',
        applyHeading: 'ભાષા પસંદ કરો / Choose Language',
    }, {
        icon: 'assets/images-custom/language/punjabi.png',
        lang: 'Punjabi',
        dispText: 'ਪੰਜਾਬੀ',
        selected: false,
        applyText: 'ਲਾਗੂ ਕਰੋ',
        code: 'pu',
        applyHeading: 'ਭਾਸ਼ਾ ਚੁਣੋ / Choose Language',
    }];
    selectedLanguage: any = {
        icon: 'assets/images-custom/language/english.png',
        lang: 'English',
        dispText: 'English',
        selected: true,
        applyText: 'Apply',
        shortCode: 'en',
        applyHeading: 'Choose Language',
    };
    public closeResult: string;
    public modalOpen = false;
    languageList = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private modalService: NgbModal,
        public homeService: HomeService,
        private appService: AppService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.homeService.getLanguages().subscribe((languages) => {
            if (languages.length > 0) {
                // this.languageList = languages;
                const selectedlang: any = this.appService.getLocalStorageItem('lang', true);
                if (selectedlang == null) {
                    languages[0].selected = true;
                    this.applyLanguage();
                }
                /*console.log(selectedlang);
                const chosenLanguage = _.filter(languages, {shortCode: selectedlang.shortCode});
                console.log(chosenLanguage);*/
                console.log(selectedlang);
                // this.translate.setDefaultLang(selectedlang[0].shortCode);
                // this.cdr.detectChanges();
                const languagesData = [];
                each(languages, (lang) => {
                    const langData = {
                        icon: '',
                        lang: lang.name,
                        dispText: lang.i18Text,
                        selected: false,
                        applyText: lang.i18Text,
                        shortCode: lang.shortCode,
                    };
                    if (lang.shortCode === selectedlang[0].shortCode) {
                        langData.selected = true;
                    }
                    languagesData.push(langData);
                    /*if (localStorage.getItem('languageSelected') === 'true') {
                        if (chosenLanguage[0].code === lang.shortCode) {

                            const langData = {
                                icon: 'assets/images-custom/language/punjabi.png',
                                lang: lang.name,
                                dispText: lang.i18Text,
                                selected: true,
                                applyText: lang.i18Text,
                                code: lang.shortCode,
                            };
                            languagesData.push(langData);
                        } else {
                            const langData = {
                                icon: 'assets/images-custom/language/punjabi.png',
                                lang: lang.name,
                                dispText: lang.i18Text,
                                selected: false,
                                applyText: lang.i18Text,
                                code: lang.shortCode,
                            };
                            languagesData.push(langData);
                        }


                    } else {
                        const langData = {
                            icon: 'assets/images-custom/language/punjabi.png',
                            lang: lang.name,
                            dispText: lang.i18Text,
                            selected: false,
                            applyText: lang.i18Text,
                            code: lang.shortCode,
                        };
                        languagesData.push(langData);
                    }*/
                });
                console.log(languagesData);
                this.languageList = languagesData;

            } else {
                this.languageList = [{
                    icon: 'assets/images-custom/language/english.png',
                    lang: 'English',
                    dispText: 'English',
                    selected: true,
                    applyText: 'Apply',
                    code: 'en',
                    applyHeading: 'Choose Language',
                }];
            }
        });
    }

    ngAfterViewInit(): void {
        /*if (localStorage.getItem('languageSelected') !== 'true') {
            const selectedlang: any = this.appService.getLocalStorageItem('lang', true);
            // const chosenLanguage = _.filter(this.languages, { lang: selectedlang[0].lang });
            var allData = this.languageList.filter((lang) => {
                return lang.shortCode !== selectedlang[0].shortCode;
            });
            allData.push(selectedlang);
            // console.log('all langs ' ,allData);
            this.openModal();
        }*/
        // localStorage.setItem('languageSelected', 'true');
    }

    openModal() {
        if (isPlatformBrowser(this.platformId)) { // For SSR
            this.modalService.open(this.LanguageSelectionModal, {
                size: 'lg',
                ariaLabelledBy: 'LanguageSelection-Modal',
                centered: true,
                keyboard: false,
                windowClass: 'theme-modal newsletterm NewsLetterModal'
            }).result.then((result) => {
                this.modalOpen = true;
                `Result ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        if (this.modalOpen) {
            this.modalService.dismissAll();
        }
    }

    selectLanguage(lang: any) {
        // this.translate.setDefaultLang(lang.code);
        this.selectedLanguage = lang;
        _.forEach(this.languageList, (language) => {
                if (language.shortCode === lang.shortCode) {
                    language.selected = true;
                } else {
                    language.selected = false;
                }
            }
        )
        ;
    }

    applyLanguage() {
        const chosenLanguage = _.filter(this.languageList, {selected: true});
        this.appService.setLocalStorageItem('lang', chosenLanguage, true);
        // localStorage.setItem('languageChosen', chosenLanguage);
        localStorage.setItem('languageSelected', 'true');
        this.modalService.dismissAll();
        window.location.reload();
    }
}
